import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { baseActiveReduxConfig } from "../config/advancedTableConfig";

const tableAdapter = createEntityAdapter();

const initialState = tableAdapter.getInitialState({
  currentTableId: null,
});

export const advancedTableSlice = createSlice({
  name: "advancedTable",
  initialState,
  reducers: {
    setSelectedTable: (state, action) => {
      const { id, resetTable, ...otherData } = action.payload;
      const { selectAll, selectById } = tableAdapter.getSelectors();
      // TODO: REMOVE THIS AFTER CLEANUP REDUX IMPLEMENTATION
      if (state.currentTableId !== id) {
        const updates = selectAll(state).map(({ id }) => ({ id, changes: { originalRows: [], rowData: [] } }));
        tableAdapter.updateMany(state, updates);
      }
      const entity = selectById(state, id);

      const baseAdvancedTableConfig = baseActiveReduxConfig(id, otherData);
      if (!entity) tableAdapter.addOne(state, baseAdvancedTableConfig);
      if (entity && resetTable) tableAdapter.updateOne(state, { id, changes: baseAdvancedTableConfig });
      state.currentTableId = id;
    },

    setActiveShowColumns: (state, { payload: columnIds }) => {
      const entityId = state.currentTableId;
      if (entityId) {
        tableAdapter.updateOne(state, {
          id: entityId,
          changes: { showColumns: columnIds },
        });
      }
    },

    setPaginationChange: (state, action) => {
      const { newPage: activePage, rowsPerPage: rowsPerPageSelected } = action.payload;
      const entityId = state.currentTableId;
      if (entityId) {
        let changes = {
          ...((activePage || activePage === 0) && { activePage }),
          ...(rowsPerPageSelected && { rowsPerPageSelected }),
        };
        tableAdapter.updateOne(state, {
          id: entityId,
          changes,
        });
      }
    },

    setFilterTableStatus: (state, { payload: filterTable }) => {
      const entityId = state.currentTableId;
      if (entityId) {
        tableAdapter.updateOne(state, {
          id: entityId,
          changes: { filterTable, selectedRows: [] },
        });
      }
    },

    setSelectedRows: (state, { payload: { isChecked = false, id = null } }) => {
      const entityId = state.currentTableId;
      const entity = tableAdapter.getSelectors().selectById(state, entityId);
      if (!entity) return;

      let newSelected = new Set(entity.selectedRows);
      if (id != null) isChecked ? newSelected.add(id) : newSelected.delete(id);
      else newSelected = new Set(isChecked ? entity.rowData : []);

      tableAdapter.updateOne(state, {
        id: entityId,
        changes: { selectedRows: Array.from(newSelected) },
      });
    },

    sortDataInOrder: (state, { payload }) => {
      const entityId = state.currentTableId;
      if (entityId) {
        tableAdapter.updateOne(state, {
          id: entityId,
          changes: { sortDataInOrder: payload },
        });
      }
    },

    setDataDateRange: (state, { payload }) => {
      const entityId = state.currentTableId;
      if (entityId) {
        const selectedDateRange = payload;
        tableAdapter.updateOne(state, {
          id: entityId,
          changes: { selectedDateRange },
        });
      }
    },

    setSearchString: (state, { payload: searchStr = "" }) => {
      const entityId = state.currentTableId;
      if (entityId) {
        tableAdapter.updateOne(state, {
          id: entityId,
          changes: { searchStr: String(searchStr) },
        });
      }
    },

    setOriginalDataRows: (state, { payload: originalRows = [] }) => {
      const entityId = state.currentTableId;
      if (entityId) {
        tableAdapter.updateOne(state, {
          id: entityId,
          changes: { originalRows, selectedRows: [] },
        });
      }
    },

    setDataRows: (state, { payload: rowData = [] }) => {
      const entityId = state.currentTableId;
      if (entityId) {
        tableAdapter.updateOne(state, {
          id: entityId,
          changes: { rowData },
        });
      }
    },

    setNewFilter: (state, { payload: { columnId = null, columnLabel = "", columnValues = [] } }) => {
      if (!columnId || !columnValues.length || !state.currentTableId) return;
      const entity = tableAdapter.getSelectors().selectById(state, state.currentTableId);
      if (!entity) return;

      columnValues = Array.from(new Map(columnValues.map((value) => [value.valueId, value])).values()).sort(
        (a, b) => -a.valueLabel.localeCompare(b.valueLabel)
      );

      const index = entity.savedFilters.findIndex((filter) => filter.columnId === columnId);
      const updatedFilters = [...entity.savedFilters];
      if (index !== -1) updatedFilters[index] = { ...updatedFilters[index], columnValues };
      else updatedFilters.push({ columnId, columnLabel, columnValues });

      tableAdapter.updateOne(state, {
        id: state.currentTableId,
        changes: {
          savedFilters: updatedFilters,
          activeFilters: [...new Set([...entity.activeFilters, columnId])],
          filterTable: updatedFilters.length > 0,
        },
      });
    },

    setActiveFilters: (state, { payload: activeFilters }) => {
      const entityId = state.currentTableId;
      if (entityId) {
        tableAdapter.updateOne(state, {
          id: entityId,
          changes: { activeFilters },
        });
      }
    },

    removeFilter: (state, { payload: columnId }) => {
      const entity = tableAdapter.getSelectors().selectById(state, state.currentTableId);
      if (!entity) return;

      tableAdapter.updateOne(state, {
        id: state.currentTableId,
        changes: {
          savedFilters: entity.savedFilters.filter((filter) => filter.columnId !== columnId),
          activeFilters: entity.activeFilters.filter((colId) => colId !== columnId),
          filterTable: entity.savedFilters.length - 1 > 0,
        },
      });
    },

    setCustomData: (state, { payload: { columnId, rowId, data, clear = false } }) => {
      const entity = tableAdapter.getSelectors().selectById(state, state.currentTableId);
      if (!entity || (clear && !columnId)) return;

      tableAdapter.updateOne(state, {
        id: state.currentTableId,
        changes: {
          customData: clear
            ? { [columnId]: {} }
            : {
                ...entity.customData,
                [columnId]: {
                  ...entity.customData[columnId],
                  [rowId]: { ...(entity.customData[columnId]?.[rowId] || {}), data },
                },
              },
        },
      });
    },
  },
  extraReducers: () => {},
});

export const {
  setSelectedTable,
  setActiveShowColumns,
  setPaginationChange,
  setFilterTableStatus,
  setSelectedRows,
  sortDataInOrder,
  setDataDateRange,
  setSearchString,
  setOriginalDataRows,
  setDataRows,
  setNewFilter,
  setActiveFilters,
  removeFilter,
  setCustomData,
} = advancedTableSlice.actions;

// Base selector for the advanced table state
const selectAdvancedTableState = (state) => state.advancedTable;

// Reusable helper to get the current table from the state
const getCurrentTable = createSelector([selectAdvancedTableState], (state) => tableAdapter.getSelectors().selectById(state, state.currentTableId));

// Reusable selector to get table property
const selectTableProperty = (property) => createSelector([getCurrentTable], (table) => table[property]);

// Memoized selectors for specific table properties
const selectRows = selectTableProperty("rowData");
const selectCustomData = selectTableProperty("customData");
export const selectCheckedRows = selectTableProperty("selectedRows");
export const selectCurrentTableId = createSelector([selectAdvancedTableState], (state) => state.currentTableId);
export const selectActiveColumnsIds = selectTableProperty("showColumns");
export const selectSearchString = selectTableProperty("searchStr");
export const selectOriginalRows = selectTableProperty("originalRows");
export const selectOriginalColumns = selectTableProperty("columns");
export const selectPage = selectTableProperty("activePage");
export const selectRowsPerPage = selectTableProperty("rowsPerPageSelected");
export const selectRowsPerPageOptions = selectTableProperty("rowsPerPageOptions");
export const selectFilterTableStatus = selectTableProperty("filterTable");
export const selectDataInOrder = selectTableProperty("sortDataInOrder");
export const selectDateRange = selectTableProperty("selectedDateRange");
export const selectSavedFilters = selectTableProperty("savedFilters");
export const selectActiveFilters = selectTableProperty("activeFilters");

export const selectActiveColumns = createSelector([selectActiveColumnsIds, selectOriginalColumns], (active, original) =>
  original.filter((col) => active.includes(col.id))
);
export const selectActiveRows = createSelector([selectRows, selectOriginalRows], (active, original) =>
  original.filter((row) => active.includes(row.id))
);
export const selectRowCount = createSelector([selectRows], (rows) => rows.length);
export const selectActiveFiltersCount = createSelector([selectActiveFilters], (filters) => filters.length);
export const selectOriginalRowCount = createSelector([selectOriginalRows], (rows) => rows.length);
export const selectSelectedRowsCount = createSelector([selectCheckedRows], (rows) => rows.length);
export const selectPrimaryColumnId = createSelector([selectOriginalColumns], (columns) => columns.find((column) => column.primaryKey)?.id);

export const selectCustomTableCellData = createSelector(
  [selectCustomData, (_, columnId) => columnId, (_, __, rowId) => rowId],
  (customData, columnId, rowId) => customData?.[columnId]?.[rowId]?.data
);

export const selectAllCustomDataWithPrimaryKey = createSelector(
  [selectCustomData, (_, columnId) => columnId],
  (customData, columnId) => customData?.[columnId]
);

export const selectAllCurrentDataWithPrimaryKey = createSelector(
  [selectOriginalRows, selectPrimaryColumnId, (_, columnId) => columnId],
  (rows, primaryColumnId, desiredColumnId) => {
    const result = {};
    rows.forEach((row) => {
      const primaryKeyValue = row[primaryColumnId];
      const desiredValue = row[desiredColumnId];

      if (primaryKeyValue !== undefined && desiredValue !== undefined) {
        result[primaryKeyValue] = { data: desiredValue };
      }
    });

    return result;
  }
);

export const selectIfCurrentDataIsSameAsCustomData = createSelector(
  [(state, columnId) => selectAllCustomDataWithPrimaryKey(state, columnId), (state, columnId) => selectAllCurrentDataWithPrimaryKey(state, columnId)],
  (newData, currentData) => Object.keys(currentData).every((key) => !newData?.[key] || currentData?.[key]?.data === newData?.[key]?.data)
);

// Custom functions to avoid re-renders
export const makeSelectRowsBasedOnOption = createSelector(
  [selectOriginalRows, selectRows, selectCheckedRows, (_, optionId) => optionId],
  (originalRows, displayedRowIds, selectedRowIds, optionId) => {
    switch (optionId) {
      case "export-all-rows":
        return originalRows.map((row) => row.id);
      case "export-displayed-rows":
        return displayedRowIds;
      case "export-selected-rows":
        return selectedRowIds;
      default:
        return [];
    }
  }
);

export const isRowIdSelected = (id) => createSelector([selectCheckedRows], (rows) => rows.includes(id));
export default advancedTableSlice.reducer;
