import { Cancel, Clear, DoneAll, ExpandCircleDown, ModeEdit } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { createElement, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterDataBasedOnAttributes } from "../../app/utils/middleware";
import { MuiTooltip } from "../../styled/commonStyles";
import { StyledTableCell, StyledTableRow } from "../../styled/tableStyles";
import { removeFilter, selectActiveFilters, selectSavedFilters, setActiveFilters, setNewFilter } from "../slices/advancedTable";
import { AutocompleteOptionsField, SelectOptionsField } from "./InputUI";
import { Transition, UiIconButton } from "./UiUtils";

const AddNewFilter = ({ filterConfig: { filterOptionsHook: useGetFilterOptionValues, enableFiltersOnColumns }, editFilter, onSetEditFilter }) => {
  const theme = useTheme();
  const [columnOption, setColumnOption] = useState({ columnId: "" });
  const [configOptions] = useGetFilterOptionValues(columnOption.fetchOptionsById, columnOption.transformOptions);
  const [filterDataValues, setFilterDataValues] = useState([]);

  const [addFilter, setAddFilter] = useState(false);
  const dispatch = useDispatch();

  const onChangeColumnOption = (value) => {
    setColumnOption(enableFiltersOnColumns.find(({ columnId }) => columnId === value));
    setFilterDataValues([]);
  };

  const onChangeFilterValues = (selectedValues) => setFilterDataValues(selectedValues);

  const onAddFilter = () => {
    dispatch(
      setNewFilter({
        columnId: columnOption.columnId,
        columnLabel: columnOption.columnLabel,
        columnValues: filterDataValues.map(({ valueId, valueLabel }) => ({ valueId, valueLabel })),
      })
    );
    setAddFilter(false);
    onSetEditFilter(null)();
  };

  useEffect(() => {
    if (editFilter) {
      setAddFilter(true);
      setColumnOption(enableFiltersOnColumns.find(({ columnId }) => columnId === editFilter.columnId));
      setFilterDataValues(editFilter.columnValues);
    }
  }, [enableFiltersOnColumns, editFilter]);

  return (
    <Box sx={{ mb: 2 }}>
      {addFilter ? (
        <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
          <Box component="div" sx={{ display: "flex", flex: 1, alignItems: "center" }}>
            <SelectOptionsField
              autoFocus
              label="Filter By"
              value={columnOption.columnId}
              onChange={onChangeColumnOption}
              fieldStyleProps={{ m: 1, ml: 0 }}
            >
              {enableFiltersOnColumns.map(({ columnId, columnLabel }) => (
                <MenuItem key={columnId} value={columnId}>
                  {columnLabel}
                </MenuItem>
              ))}
            </SelectOptionsField>
            <AutocompleteOptionsField
              sx={{ m: 1, ml: 0 }}
              options={configOptions}
              {...(!!columnOption.groupOptionsBy && { groupBy: (option) => option[columnOption.groupOptionsBy] })}
              value={filterDataValues}
              onChange={onChangeFilterValues}
              label="Filter Values"
              placeholder="Search or select values..."
              multiple
              limitTags={2}
              noOptionsText="No Options Available"
              getOptionLabel={(option) => option.valueLabel}
              isOptionEqualToValue={(option, value) => option.valueId === value.valueId}
              renderOptionComponent={
                !!columnOption.renderOptionComponent && ((option) => createElement(columnOption.renderOptionComponent, { option, theme }))
              }
              filterFunction={columnOption.filterOptionsFunction}
              uniqueKeyId="valueId"
            />
          </Box>
          <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
            <UiIconButton title="Add Filter" onClickIcon={onAddFilter} iconColor="success.main">
              <DoneAll />
            </UiIconButton>
            <UiIconButton title="Cancel" onClickIcon={() => setAddFilter(false)} iconColor="error.main">
              <Clear />
            </UiIconButton>
          </Box>
        </Box>
      ) : (
        <Button fullWidth variant="contained" sx={{ fontSize: 14 }} onClick={() => setAddFilter(true)}>
          Add New Filter
        </Button>
      )}
    </Box>
  );
};

const FilterTable = ({ onSetEditFilter }) => {
  const savedFilters = useSelector(selectSavedFilters);
  const activeFilters = useSelector(selectActiveFilters);
  const dispatch = useDispatch();
  const onSelectAllClick = (event) => dispatch(setActiveFilters(event.target.checked ? savedFilters.map((filter) => filter.columnId) : []));
  const onSelectSingleFilter = (columnId) => () => {
    const selectedIndex = activeFilters.indexOf(columnId);
    let newActiveFilters = new Set(activeFilters);
    if (selectedIndex === -1) newActiveFilters.add(columnId);
    else newActiveFilters.delete(columnId);
    dispatch(setActiveFilters([...newActiveFilters]));
  };
  const onRemoveFilter = (columnId) => () => dispatch(removeFilter(columnId));

  return (
    <TableContainer style={{ height: "300px" }}>
      <Table size="small" stickyHeader sx={{ minWidth: "750px" }}>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell padding="checkbox">
              <MuiTooltip fontSize={10} title="Enable/Disable all filters">
                <Checkbox
                  color="primary"
                  indeterminate={activeFilters.length > 0 && activeFilters.length < savedFilters.length}
                  checked={savedFilters.length > 0 && activeFilters.length === savedFilters.length}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all",
                  }}
                />
              </MuiTooltip>
            </StyledTableCell>
            <StyledTableCell align="center">Label</StyledTableCell>
            <StyledTableCell align="center">Values</StyledTableCell>
            <StyledTableCell align="center">Actions</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody sx={{ "& :last-child td, & :last-child th": { border: 0 } }}>
          {savedFilters.length === 0 && (
            <TableRow>
              <StyledTableCell colSpan={4} sx={{ p: 2 }}>
                <Typography sx={{ textAlign: "center", fontSize: 14 }}>No Saved Filters Found</Typography>
              </StyledTableCell>
            </TableRow>
          )}
          {savedFilters.map(({ columnId, columnLabel, columnValues }) => (
            <TableRow key={columnId}>
              <MuiTooltip fontSize={10} title={`${activeFilters.includes(columnId) ? "Disable" : "Enable"} filter`}>
                <StyledTableCell padding="checkbox" onClick={onSelectSingleFilter(columnId)}>
                  <Checkbox
                    checked={activeFilters.includes(columnId)}
                    inputProps={{
                      "aria-labelledby": columnId,
                    }}
                  />
                </StyledTableCell>
              </MuiTooltip>
              <StyledTableCell align="center">{columnLabel}</StyledTableCell>
              <StyledTableCell align="center" sx={{ maxWidth: "350px", wordBreak: "break-word", wordWrap: "break-word" }}>
                {columnValues.map(({ valueLabel }) => valueLabel).join(", ")}
              </StyledTableCell>
              <StyledTableCell align="center">
                <UiIconButton title="Edit Filter" onClickIcon={onSetEditFilter({ columnId, columnLabel, columnValues })} iconSize={18}>
                  <ModeEdit sx={{ color: (theme) => `${theme.palette.primary.main} !important` }} />
                </UiIconButton>
                <UiIconButton title="Delete Filter" iconColor="error.main" onClickIcon={onRemoveFilter(columnId)} iconSize={18}>
                  <Cancel sx={{ color: (theme) => `${theme.palette.error.main} !important` }} />
                </UiIconButton>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default function FilterDataDialogModal({ columnProps, filterTableConfig, handleClose }) {
  const dispatch = useDispatch();
  const activeFilters = useSelector(selectActiveFilters);
  const [expandSavedFilterAccordion, setExpandSavedFilterAccordion] = useState(true);
  const [editFilter, setEditFilter] = useState(null);

  const onSetEditFilter = (value) => () => setEditFilter(value);
  const onHandleExpandFilterAccordion = useCallback(() => setExpandSavedFilterAccordion((prev) => !prev), []);

  const onApplyFilterChanges = useCallback(
    () =>
      dispatch(
        filterDataBasedOnAttributes({
          payload: { filterTable: true },
          columnProps,
        })
      ),
    [columnProps, dispatch]
  );

  useEffect(() => onApplyFilterChanges(), [onApplyFilterChanges, activeFilters]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={true}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          backgroundColor: "background.default",
          border: "1px solid",
          borderColor: "primary.sectionBorder100",
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 600 }}>
        <ListItemText
          primary="Saved Filters"
          secondary="Filters will be automatically applied upon selection"
          primaryTypographyProps={{ variant: "h6" }}
          secondaryTypographyProps={{ variant: "body1" }}
        />
      </DialogTitle>
      <DialogContent sx={{ m: 1 }}>
        <Box>
          <AddNewFilter filterConfig={filterTableConfig} editFilter={editFilter} onSetEditFilter={onSetEditFilter} />
          <Accordion
            expanded={expandSavedFilterAccordion}
            onChange={onHandleExpandFilterAccordion}
            sx={{
              backgroundColor: `background.paper`,
              boxShadow: "none !important",
              borderRadius: "4px",
              border: "1px solid",
              borderColor: "primary.sectionBorder600",
              "& .MuiAccordionSummary-content.Mui-expanded": {
                marginTop: "0 !important",
                marginBottom: "0 !important",
                minHeight: "fit-content",
              },
              "& .MuiSvgIcon-root, & .MuiIcon-root": {
                color: "text.primary",
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandCircleDown />} id="expand-saved-filters">
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>Saved Filters</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: "background.default", px: 2, py: 3 }}>
              <FilterTable onSetEditFilter={onSetEditFilter} />
            </AccordionDetails>
          </Accordion>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close Filters
        </Button>
      </DialogActions>
    </Dialog>
  );
}
