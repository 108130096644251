import { ListItem, ListItemButton, ListItemText, Paper } from "@mui/material";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { AutoSizer, List } from "react-virtualized";

const SuggestionRow = React.memo(({ item, isSelected, onClick, style }) => (
  <ListItem dense disablePadding style={style} key={item.id}>
    <ListItemButton selected={isSelected} onClick={onClick} sx={{ width: "100%" }}>
      <ListItemText primary={item.label} primaryTypographyProps={{ fontWeight: 500 }} secondary={item.id} />
    </ListItemButton>
  </ListItem>
));

const TasksSuggestionListUI = forwardRef((props, ref) => {
  const { items, command } = props;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const listRef = useRef();

  const itemSize = 52;
  const maxItemsToShow = 5;
  const listHeight = Math.min(items.length * itemSize, maxItemsToShow * itemSize);

  const selectItem = useCallback(
    (index) => {
      if (index >= items.length) return;
      const suggestion = items[index];
      const mentionItem = {
        id: suggestion.id,
        label: suggestion.label,
      };
      command(mentionItem);
    },
    [items, command]
  );

  const upHandler = useCallback(() => {
    setSelectedIndex((prevIndex) => (prevIndex + items.length - 1) % items.length);
  }, [items.length]);

  const downHandler = useCallback(() => {
    setSelectedIndex((prevIndex) => (prevIndex + 1) % items.length);
  }, [items.length]);

  const enterHandler = useCallback(() => {
    selectItem(selectedIndex);
  }, [selectItem, selectedIndex]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToRow(selectedIndex);
    }
  }, [selectedIndex]);

  useEffect(() => {
    setSelectedIndex(0);
  }, [items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }
      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }
      if (event.key === "Enter") {
        enterHandler();
        return true;
      }
      return false;
    },
  }));

  const rowRenderer = useCallback(
    ({ index, style }) => <SuggestionRow item={items[index]} isSelected={index === selectedIndex} onClick={() => selectItem(index)} style={style} />,
    [items, selectedIndex, selectItem]
  );

  if (items.length === 0) return null;

  return (
    <Paper
      elevation={5}
      sx={{
        height: listHeight,
        minWidth: "280px",
        overflow: "hidden",
      }}
    >
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            ref={listRef}
            height={listHeight}
            width={width}
            rowHeight={itemSize}
            rowCount={items.length}
            rowRenderer={rowRenderer}
            scrollToIndex={selectedIndex}
            scrollToAlignment="center"
            overscanRowCount={5}
          />
        )}
      </AutoSizer>
    </Paper>
  );
});

TasksSuggestionListUI.displayName = "TasksSuggestionListUI";
export default TasksSuggestionListUI;
