import { debounce } from "lodash";
import { useCallback, useRef } from "react";
import {
  ATTENDANCE_ACTION,
  AUTH_ACTION,
  CONFIG_ACTION,
  EMPLOYEE_MONITOR_ACTION,
  FILE_STORAGE,
  INVENTORY_ACTION,
  LOGGER_ACTION,
  PROFILE_ACTION,
  TASKS_ACTION
} from "../app/constants/api";
import { DATE_TIME_FORMAT } from "../app/constants/common";
import { uiToServer } from "../app/utils/utilityFunctions";
import { useConfigActionMutation } from "../features/AppSettings/appApiSlice";
import { useAuthActionMutation } from "../features/Auth/slice/apiSlice";
import { useAttendanceActionMutation } from "../features/EmployeeAttendance/slice/apiSlice";
import { useEmployeeMonitorActionMutation } from "../features/EmployeeLocation/slice/apiSlice";
import { useInventoryActionMutation } from "../features/InventoryManagement/slice/apiSlice";
import { useLoggerActionMutation } from "../features/Logger/slice/loggerApiSlice";
import { useTasksActionMutation } from "../features/SystemTasks/slice/apiSlice";
import { useFileStorageMutation } from "../features/Tasks/slice/taskApiSlice";
import { useProfileActionMutation } from "../features/UserAnalysis/slice/userProfileApiSlice";

let globalDebouncedTrigger = null;

/* INFO: Custom hook for creating a debounced mutation function */
const useDebouncedMutation = (trigger, useGlobalInstance = false, debounceTime = 200) => {
  const debouncedRef = useRef(null);
  if (!debouncedRef.current) debouncedRef.current = debounce(trigger, debounceTime);
  return useGlobalInstance ? globalDebouncedTrigger || (globalDebouncedTrigger = debounce(trigger, debounceTime)) : debouncedRef.current;
};

/* INFO: Generic hook for creating debounced mutation with action */
const useActionPayloadMutation = (useActionMutationHook, action, payloadBuilder, useDebounce = true) => {
  const [trigger, props] = useActionMutationHook();
  const debouncedTrigger = useDebouncedMutation(trigger);
  const triggerMutation = useCallback(
    (...args) =>
      useDebounce
        ? debouncedTrigger({ action, payload: payloadBuilder?.(...args) ?? {} })
        : trigger({ action, payload: payloadBuilder?.(...args) ?? {} }),
    [debouncedTrigger, trigger, useDebounce, action, payloadBuilder]
  );

  return {
    triggerMutation,
    ...props,
  };
};

/* INFO: Implementing 'useActionPayloadMutation' */
const useLoginMutation = () =>
  useActionPayloadMutation(
    useAuthActionMutation,
    AUTH_ACTION.LOGIN,
    useCallback((email, password) => ({ email, password }), []),
    false
  );

const useRequestResetPasswordMutation = () =>
  useActionPayloadMutation(
    useAuthActionMutation,
    AUTH_ACTION.RESET_PASSWORD_REQUEST,
    useCallback((email) => ({ email }), []),
    false
  );

const useResetPasswordMutation = () =>
  useActionPayloadMutation(
    useAuthActionMutation,
    AUTH_ACTION.RESET_PASSWORD,
    useCallback((email, token, password, confirm_password) => ({ email, token, password, confirm_password }), []),
    false
  );

const useSignupMutation = () =>
  useActionPayloadMutation(
    useAuthActionMutation,
    AUTH_ACTION.SIGNUP,
    useCallback((email, password, confirm_password) => ({ email, password, confirm_password }), []),
    false
  );

const useVerifyEmailMutation = () =>
  useActionPayloadMutation(
    useAuthActionMutation,
    AUTH_ACTION.VERIFY_EMAIL,
    useCallback((email) => ({ email }), [])
  );

const useLogoutMutation = () => useActionPayloadMutation(useAuthActionMutation, AUTH_ACTION.LOGOUT, undefined, false);
const useFetchSystemTaskCountMutation = () => useActionPayloadMutation(useTasksActionMutation, TASKS_ACTION.COUNT);
const useFetchSystemTaskItemsMutation = () =>
  useActionPayloadMutation(
    useTasksActionMutation,
    TASKS_ACTION.ITEMS,
    useCallback(
      ([dateFrom, dateTo] = [], employeeId, taskStatus = []) => ({
        exec_time_start: dateFrom,
        exec_time_end: dateTo,
        ...(taskStatus.length && { task_status: taskStatus }),
        ...(employeeId && { ern: employeeId }),
      }),
      []
    )
  );
const useCreateTaskMutation = () =>
  useActionPayloadMutation(
    useTasksActionMutation,
    TASKS_ACTION.CREATE_TASK,
    useCallback((payload) => payload, [])
  );

const useUpdateExistingTaskMutation = () =>
  useActionPayloadMutation(
    useTasksActionMutation,
    TASKS_ACTION.UPDATE_TASK,
    useCallback((taskId, taskData) => ({ task_id: taskId, task_data: taskData }), [])
  );

const useGetTaskTreeListMutation = () =>
  useActionPayloadMutation(
    useTasksActionMutation,
    TASKS_ACTION.TASKS_TREE_LIST,
    useCallback((currentTaskId) => ({ task_id: currentTaskId }), [])
  );

const useUpdateTaskInventoryPartsMutation = () =>
  useActionPayloadMutation(
    useTasksActionMutation,
    TASKS_ACTION.UPDATE_TASK_PART,
    useCallback((taskId, partArray) => ({ task_id: taskId, parts: partArray }), [])
  );

const useGetTaskDetailsMutation = () =>
  useActionPayloadMutation(
    useTasksActionMutation,
    TASKS_ACTION.TASK_DETAILS,
    useCallback((taskId) => ({ task_id: taskId }), [])
  );

const useAddTaskCommentMutation = () =>
  useActionPayloadMutation(
    useTasksActionMutation,
    TASKS_ACTION.ADD_COMMENT,
    useCallback((taskId, comment, filesArray = []) => ({ task_id: taskId, msg_txt: comment, file_ref: filesArray }), [])
  );

const useExportReportMutation = () =>
  useActionPayloadMutation(
    useTasksActionMutation,
    TASKS_ACTION.EXPORT_REPORT,
    useCallback((id, rows = undefined) => ({ id, rows }), [])
  );

const useGetEmployeeLocationCoordinatesMutation = () =>
  useActionPayloadMutation(useEmployeeMonitorActionMutation, EMPLOYEE_MONITOR_ACTION.GET_COORDINATES);

const useGetVehicleDetailsMutation = () =>
  useActionPayloadMutation(
    useInventoryActionMutation,
    INVENTORY_ACTION.GET_ALL_VEHICLES,
    useCallback((shouldLoadVhcParts = true) => ({ vhc_prts: shouldLoadVhcParts }), [])
  );
const useGetAllInventoryPartsMutation = () => useActionPayloadMutation(useInventoryActionMutation, INVENTORY_ACTION.GET_ALL_PARTS);
const useGetAllInventoryPartsUsedListMutation = () =>
  useActionPayloadMutation(
    useInventoryActionMutation,
    INVENTORY_ACTION.GET_PARTS_USED_LIST,
    useCallback((dateRange = []) => ({ ...(dateRange.length === 2 && { from: dateRange[0], to: dateRange[1] }) }), [])
  );

const useGetVehicleHistory = () =>
  useActionPayloadMutation(
    useInventoryActionMutation,
    INVENTORY_ACTION.GET_VEHICLE_HISTORY,
    useCallback((vehicleId) => ({ vhc_id: vehicleId }), [])
  );

const useDeleteInventoryPartsMutation = () =>
  useActionPayloadMutation(
    useInventoryActionMutation,
    INVENTORY_ACTION.REMOVE_VEHICLE_PARTS,
    useCallback(
      (vehicleId, vehicleParts) => ({
        vhc_id: vehicleId,
        parts: vehicleParts,
      }),
      []
    )
  );

const useUnassignVehicleMutation = () =>
  useActionPayloadMutation(
    useInventoryActionMutation,
    INVENTORY_ACTION.UNASSIGN_VEHICLE,
    useCallback((vehicleId) => ({ vhc_id: vehicleId }), [])
  );

const useUpdateVehicleDetailMutation = () =>
  useActionPayloadMutation(
    useInventoryActionMutation,
    INVENTORY_ACTION.UPDATE_VEHICLE_DETAILS,
    useCallback((payload) => payload, [])
  );

const useUpdateVehiclePartsMutation = () =>
  useActionPayloadMutation(
    useInventoryActionMutation,
    INVENTORY_ACTION.UPDATE_VEHICLE_PARTS,
    useCallback(
      (vehicleId, vehiclePartsArrayOfObj) => ({
        vhc_id: vehicleId,
        parts: vehiclePartsArrayOfObj,
      }),
      []
    )
  );

const useGetInventoryPartAudit = () =>
  useActionPayloadMutation(
    useInventoryActionMutation,
    INVENTORY_ACTION.GET_VEHICLE_PART_AUDIT,
    useCallback((vehicleId, partId) => ({ vhc_id: vehicleId, part_id: partId }), [])
  );

const useGetConflictedAttendanceListMutation = () =>
  useActionPayloadMutation(
    useAttendanceActionMutation,
    ATTENDANCE_ACTION.GET_CONFLICTED_ATTENDANCE,
    useCallback(
      ([dateFrom, dateTo] = [], ern, show_declined) => ({
        ...((dateFrom || dateTo) && { bus_dt: [dateFrom, dateTo ?? dateFrom].filter(Boolean) }),
        ...(ern && { ern }),
        ...(show_declined && { show_declined }),
      }),
      []
    )
  );

const useGetRoutineAttendanceListMutation = () =>
  useActionPayloadMutation(
    useAttendanceActionMutation,
    ATTENDANCE_ACTION.GET_ROUTINE_ATTENDANCE,
    useCallback(
      ([dateFrom, dateTo] = [], ern) => ({
        collate: false,
        bus_dt: [dateFrom ?? uiToServer(new Date(), DATE_TIME_FORMAT.SERVER_DATE), dateTo ?? uiToServer(new Date(), DATE_TIME_FORMAT.SERVER_DATE)],
        ...(ern && { ern }),
      }),
      []
    )
  );

const useGetOvertimeAttendanceListMutation = () =>
  useActionPayloadMutation(
    useAttendanceActionMutation,
    ATTENDANCE_ACTION.GET_OVERTIME_ATTENDANCE,
    useCallback(
      ([dateFrom, dateTo] = [], ern) => ({
        ...((dateFrom || dateTo) && { bus_dt: [dateFrom, dateTo ?? dateFrom].filter(Boolean) }),
        ...(ern && { ern }),
      }),
      []
    )
  );

const useGetAttendanceStatsMutation = () =>
  useActionPayloadMutation(
    useAttendanceActionMutation,
    ATTENDANCE_ACTION.GET_ATTENDANCE_STATS,
    useCallback(
      (dateFrom, dateTo, ern) => ({
        bus_dt: [dateFrom ?? uiToServer(new Date(), DATE_TIME_FORMAT.SERVER_DATE), dateTo ?? uiToServer(new Date(), DATE_TIME_FORMAT.SERVER_DATE)],
        ...(ern && { ern }),
      }),
      []
    )
  );

const useResolveAttendanceConflictMutation = () =>
  useActionPayloadMutation(
    useAttendanceActionMutation,
    ATTENDANCE_ACTION.RESOLVE_ATTENDANCE_CONFLICT,
    useCallback(
      (reqId, updatedTime, reqStatus, lastUpdatedTimeStamp) => ({
        req_id: reqId,
        new_tms: updatedTime,
        req_stat: reqStatus,
        upd_tms: lastUpdatedTimeStamp,
      }),
      []
    )
  );

  const useResolveOvertimeMutation = () =>
    useActionPayloadMutation(
      useAttendanceActionMutation,
      ATTENDANCE_ACTION.RESOLVE_OVERTIME_REQUEST,
      useCallback((data) => data, [])
    );


const useGetFileForRefMutation = () =>
  useActionPayloadMutation(
    useFileStorageMutation,
    FILE_STORAGE.DOWNLOAD_FILE,
    useCallback((fileRef) => ({ file_ref: fileRef }), [])
  );

const useUploadFileMutation = () =>
  useActionPayloadMutation(
    useFileStorageMutation,
    FILE_STORAGE.UPLOAD_FILE,
    useCallback((fileName, fileMime, fileData) => ({ file_name: fileName, file_mime: fileMime, file_data: fileData }), []),
    false
  );

const useGetKnowledgeBaseFilesData = () => useActionPayloadMutation(useFileStorageMutation, FILE_STORAGE.GET_KNOWLEDGE_BASE);
const useDeleteKnowledgeBaseFilesData = () =>
  useActionPayloadMutation(
    useFileStorageMutation,
    FILE_STORAGE.DELETE_KNOWLEDGE_BASE,
    useCallback((fileRef) => ({ file_ref: fileRef }), [])
  );
const useUploadKnowledgeBaseFileData = () =>
  useActionPayloadMutation(
    useFileStorageMutation,
    FILE_STORAGE.UPLOAD_KNOWLEDGE_BASE,
    useCallback((file_remark, file_name, file_mime, file_data) => ({ file_name, file_remark, file_mime, file_data }), []),
    false
  );

const useGetFormListDataMutation = () => useActionPayloadMutation(useConfigActionMutation, CONFIG_ACTION.FORM_LIST);

const useGetBrowseFormTableColumnConfigMutation = () =>
  useActionPayloadMutation(
    useConfigActionMutation,
    CONFIG_ACTION.FORM_TABLE_COLUMNS,
    useCallback((formId) => ({ form_id: formId }), [])
  );

const useGetBrowseFormTableRecordsMutation = () =>
  useActionPayloadMutation(
    useConfigActionMutation,
    CONFIG_ACTION.FORM_TABLE_RECORDS,
    useCallback((formId) => ({ form_id: formId }), [])
  );

const useGetManageDataFormMutation = () =>
  useActionPayloadMutation(
    useConfigActionMutation,
    CONFIG_ACTION.FORM,
    useCallback(
      (dataType, formType, formKey) => ({ form_id: dataType.toUpperCase(), mode: formType.toUpperCase(), ...(formKey && { keycol: formKey }) }),
      []
    )
  );

const useGetComboOptionsForFormMutation = () =>
  useActionPayloadMutation(
    useConfigActionMutation,
    CONFIG_ACTION.OPTIONS,
    useCallback((optionsId, [fieldId, value]) => ({ combo_id: [optionsId], form_data: { [fieldId]: value } }), [])
  );

const useSubmitFormDataMutation = () =>
  useActionPayloadMutation(
    useConfigActionMutation,
    CONFIG_ACTION.SUBMIT_FORM,
    useCallback(
      (dataType, formData, formType, formKey) => ({
        form_id: dataType.toUpperCase(),
        form_data: formData,
        mode: formType.toUpperCase(),
        ...(formKey && { keycol: formKey }),
      }),
      []
    )
  );

const useDeleteRowDataMutation = () =>
  useActionPayloadMutation(
    useConfigActionMutation,
    CONFIG_ACTION.DELETE_DATA,
    useCallback((formId, keyColArray) => ({ form_id: formId, keycol: keyColArray }), [])
  );

const useLogErrorDataMutation = () =>
  useActionPayloadMutation(
    useLoggerActionMutation,
    LOGGER_ACTION.LOG_ERROR,
    useCallback((error) => ({ error }), []),
    false
  );

const useGetDashboardConfigMutation = () => useActionPayloadMutation(useConfigActionMutation, CONFIG_ACTION.DASHBOARD);

const useGetUserProfileDetailsMutation = () =>
  useActionPayloadMutation(
    useProfileActionMutation,
    PROFILE_ACTION.GET_USER_PROFILE,
    useCallback((ern) => (ern ? { ern } : {}), [])
  );

const useSetUserProfilePictureMutation = () =>
  useActionPayloadMutation(
    useProfileActionMutation,
    PROFILE_ACTION.SET_USER_PROFILE_PICTURE,
    useCallback(
      (removePicture, fileName, fileMime, fileData) =>
        removePicture ? { remove: true } : { file_name: fileName, file_mime: fileMime, file_data: fileData },
      []
    )
  );

const useGetUserProfilePictureMutation = () =>
  useActionPayloadMutation(
    useProfileActionMutation,
    PROFILE_ACTION.GET_USER_PROFILE_PICTURE,
    useCallback((ern) => (ern ? { ern } : {}), []),
    false
  );

const useGetProfileNotificationsMutation = () => useActionPayloadMutation(useProfileActionMutation, PROFILE_ACTION.GET_NOTIFICATIONS);
const useDismissProfileNotificationsMutation = () =>
  useActionPayloadMutation(
    useProfileActionMutation,
    PROFILE_ACTION.DISMISS_NOTIFICATIONS,
    useCallback((notificationIdArray) => ({ notf_ids: notificationIdArray }), [])
  );

/* INFO: Special excluding the use of generic hook 'useActionPayloadMutation' */
const useGetSystemConfigMutation = () => {
  const [triggerApiOne] = useConfigActionMutation();
  const [triggerApiTwo] = useTasksActionMutation();
  const debouncedTrigger = useDebouncedMutation(() => {
    triggerApiOne({ action: CONFIG_ACTION.TASK_CONFIG });
    triggerApiTwo({ action: TASKS_ACTION.TASKS_LIST });
  }, true);

  const triggerMutation = useCallback(() => {
    debouncedTrigger();
  }, [debouncedTrigger]);

  return { triggerMutation };
};

export {
  useAddTaskCommentMutation,
  useCreateTaskMutation,
  useDeleteInventoryPartsMutation,
  useDeleteKnowledgeBaseFilesData,
  useDeleteRowDataMutation,
  useDismissProfileNotificationsMutation,
  useExportReportMutation,
  useFetchSystemTaskCountMutation,
  useFetchSystemTaskItemsMutation,
  useGetAllInventoryPartsMutation,
  useGetAllInventoryPartsUsedListMutation,
  useGetAttendanceStatsMutation,
  useGetBrowseFormTableColumnConfigMutation,
  useGetBrowseFormTableRecordsMutation,
  useGetComboOptionsForFormMutation,
  useGetConflictedAttendanceListMutation,
  useGetDashboardConfigMutation,
  useGetEmployeeLocationCoordinatesMutation,
  useGetFileForRefMutation,
  useGetFormListDataMutation,
  useGetInventoryPartAudit,
  useGetKnowledgeBaseFilesData,
  useGetManageDataFormMutation,
  useGetOvertimeAttendanceListMutation,
  useGetProfileNotificationsMutation,
  useGetRoutineAttendanceListMutation,
  useGetSystemConfigMutation,
  useGetTaskDetailsMutation,
  useGetTaskTreeListMutation,
  useGetUserProfileDetailsMutation,
  useGetUserProfilePictureMutation,
  useGetVehicleDetailsMutation,
  useGetVehicleHistory,
  useLogErrorDataMutation,
  useLoginMutation,
  useLogoutMutation,
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
  useResolveAttendanceConflictMutation,
  useResolveOvertimeMutation,
  useSetUserProfilePictureMutation,
  useSignupMutation,
  useSubmitFormDataMutation,
  useUnassignVehicleMutation,
  useUpdateExistingTaskMutation,
  useUpdateTaskInventoryPartsMutation,
  useUpdateVehicleDetailMutation,
  useUpdateVehiclePartsMutation,
  useUploadFileMutation,
  useUploadKnowledgeBaseFileData,
  useVerifyEmailMutation,
};

