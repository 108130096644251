import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItemText, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { endOfDay, isAfter, startOfDay } from "date-fns";
import frLocale from "date-fns/locale/en-IN";
import { memo, useState } from "react";
import { DATE_TIME_FORMAT } from "../../app/constants/common";
import { serverDate, uiDate, uiToServer } from "../../app/utils/utilityFunctions";
import { Transition } from "./UiUtils";

const DateRangePicker = memo(
  ({
    fromDefaultDate,
    toDefaultDate,
    formControlDateId = ["From_Date", "To_Date"],
    label = ["Start Date", "End Date"],
    size = ["medium", "medium"],
    minWidth = [false, false],
    dateProps = [{}, {}],
  }) => {
    const [startDate, setStartDate] = useState(serverDate(fromDefaultDate));
    const [endDate, setEndDate] = useState(serverDate(toDefaultDate));
    const [startPickerOpen, setStartPickerOpen] = useState(false);
    const [endPickerOpen, setEndPickerOpen] = useState(false);
    const setOpenPicker = (prevValue, picker, open) => {
      if (prevValue === open) return;
      setTimeout(() => picker(open), open ? 150 : 50);
    };

    const handleStartDateChange = (newDate) => {
      setStartDate(newDate);
      setStartPickerOpen(false);
      if (endDate && newDate && isAfter(newDate, endDate)) setEndDate(newDate);
      if (newDate) setOpenPicker(endPickerOpen, setEndPickerOpen, true);
    };

    const handleEndDateChange = (newDate) => {
      setEndDate(newDate);
      setEndPickerOpen(false);
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
        <DatePicker
          {...dateProps[0]}
          showDaysOutsideCurrentMonth
          views={["year", "month", "day"]}
          label={label[0]}
          value={startDate}
          open={startPickerOpen}
          onOpen={() => setOpenPicker(startPickerOpen, setStartPickerOpen, true)}
          onClose={() => setOpenPicker(startPickerOpen, setStartPickerOpen, false)}
          onAccept={handleStartDateChange}
          format={DATE_TIME_FORMAT.UI_DATE_FORMAT}
          slotProps={{
            layout: { sx: { "& .MuiPickersDay-root, & .MuiDayCalendar-weekDayLabel": { fontSize: "0.95rem" } } },
            popper: { placement: "bottom" },
            field: { clearable: true },
          }}
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                size={size[0]}
                name={formControlDateId[0]}
                id={formControlDateId[0]}
                autoComplete="bday"
                margin="normal"
                sx={{ minWidth: minWidth[0] ? "320px" : null, flex: 1 }}
                onClick={() => setOpenPicker(startPickerOpen, setStartPickerOpen, true)}
              />
            ),
          }}
        />
        <DatePicker
          {...dateProps[1]}
          showDaysOutsideCurrentMonth
          views={["year", "month", "day"]}
          label={label[1]}
          value={endDate}
          open={endPickerOpen}
          onOpen={() => setOpenPicker(endPickerOpen, setEndPickerOpen, true)}
          onClose={() => setOpenPicker(endPickerOpen, setEndPickerOpen, false)}
          onAccept={handleEndDateChange}
          format={DATE_TIME_FORMAT.UI_DATE_FORMAT}
          slotProps={{
            layout: { sx: { "& .MuiPickersDay-root, & .MuiDayCalendar-weekDayLabel": { fontSize: "0.95rem" } } },
            popper: { placement: "bottom" },
            field: { clearable: true },
          }}
          slots={{
            textField: (params) => (
              <TextField
                {...params}
                size={size[1]}
                name={formControlDateId[1]}
                id={formControlDateId[1]}
                autoComplete="bday"
                margin="normal"
                sx={{ minWidth: minWidth[1] ? "320px" : 0, flex: 1 }}
                onClick={() => setOpenPicker(endPickerOpen, setEndPickerOpen, true)}
              />
            ),
          }}
          minDate={startDate}
        />
      </LocalizationProvider>
    );
  }
);

export const SingleDatePicker = ({
  defaultDate,
  id = "From_Date",
  label = null,
  size = "medium",
  minWidth = false,
  onChange = null,
  slotProps = {},
  slots = {},
  disableClearable = false,
  textFieldProps = {},
  ...otherProps
}) => {
  const [date, setDate] = useState(serverDate(defaultDate));
  const [openPicker, setOpenPicker] = useState(false);
  const handleDateChange = (newDate) => {
    setDate(newDate);
    onChange?.(newDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <DatePicker
        {...otherProps}
        icon
        open={openPicker}
        onOpen={() => setOpenPicker(true)}
        onClose={() => setOpenPicker(false)}
        showDaysOutsideCurrentMonth
        views={["year", "month", "day"]}
        label={label}
        value={date}
        onAccept={handleDateChange}
        format={DATE_TIME_FORMAT.UI_DATE_FORMAT}
        slotProps={{
          ...slotProps,
          layout: { sx: { "& .MuiPickersDay-root, & .MuiDayCalendar-weekDayLabel": { fontSize: "0.95rem" } } },
          popper: { placement: "bottom" },
          field: { clearable: !disableClearable },
        }}
        slots={{
          ...slots,
          textField: (params) => (
            <TextField
              {...params}
              size={size}
              name={id}
              id={id}
              label={label}
              autoComplete="bday"
              margin="normal"
              sx={{ minWidth: minWidth ? "320px" : null, flex: 1 }}
              onClick={() => setOpenPicker(true)}
              {...textFieldProps}
            />
          ),
        }}
      />
    </LocalizationProvider>
  );
};

export const SingleTimePicker = ({
  defaultTime,
  id = "From_Time",
  label = null,
  size = "medium",
  minWidth = false,
  onChange = null,
  slotProps = {},
  slots = {},
  textFieldProps = {},
  ...otherProps
}) => {
  const [time, setTime] = useState(serverDate(defaultTime));
  const [openPicker, setOpenPicker] = useState(false);
  const handleDateChange = (newDate) => {
    setTime(newDate);
    onChange?.(newDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <TimePicker
        {...otherProps}
        open={openPicker}
        onOpen={() => setOpenPicker(true)}
        onClose={() => setOpenPicker(false)}
        label={label}
        value={time}
        onAccept={handleDateChange}
        format={DATE_TIME_FORMAT.UI_TIME_FORMAT}
        slotProps={{
          ...slotProps,
          layout: { sx: { "& .MuiPickersDay-root, & .MuiDayCalendar-weekDayLabel": { fontSize: "0.95rem" } } },
          popper: { placement: "bottom" },
        }}
        slots={{
          ...slots,
          textField: (params) => (
            <TextField
              {...params}
              size={size}
              name={id}
              id={id}
              label={label}
              autoComplete="bday"
              margin="normal"
              sx={{ minWidth: minWidth ? "320px" : null, flex: 1 }}
              onClick={() => setOpenPicker(true)}
              {...textFieldProps}
            />
          ),
        }}
      />
    </LocalizationProvider>
  );
};

export default function DateTimeRangePickerDialogModal({
  valueProviderHook: useDefaultValueProvider,
  onHandleClose,
  onHandleConfirm,
  formControlDateId: [fromDateId = "From_Date", toDateId = "To_Date"] = [],
  datePickerProps: [fromDateProps = {}, toDateProps = {}] = [],
}) {
  const { from: defaultFromDate, to: defaultToDate } = useDefaultValueProvider();
  const handleSubmitForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const from_date = uiDate(formData.get(fromDateId));
    const to_date = uiDate(formData.get(toDateId));
    onHandleConfirm({
      from: uiToServer(startOfDay(from_date)),
      to: uiToServer(endOfDay(to_date)),
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={true}
      onClose={onHandleClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          border: "1px solid",
          borderColor: "primary.sectionBorder100",
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 500 }}>
        <ListItemText
          primary="Select Date Range"
          secondary="Select the same date for both inputs to view data for a specific day. Leaving these fields blank is also permitted."
          primaryTypographyProps={{ fontSize: "inherit", fontWeight: "inherit", fontStyle: "inherit" }}
          secondaryTypographyProps={{ fontSize: "0.925rem" }}
        />
      </DialogTitle>
      <DialogContent sx={{ py: 2, px: 3 }}>
        <Box
          component="form"
          id="date-range-picker-form"
          onSubmit={handleSubmitForm}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            flexWrap: "wrap",
            width: "100%",
            "& .MuiIconButton-root": {
              color: "primary.main",
            },
          }}
        >
          <DateRangePicker
            fromDefaultDate={defaultFromDate}
            toDefaultDate={defaultToDate}
            minWidth={[true, true]}
            formControlDateId={[fromDateId, toDateId]}
            dateProps={[fromDateProps, toDateProps]}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onHandleClose}>
          Back
        </Button>
        <Button variant="contained" type="submit" form="date-range-picker-form">
          Apply Date(s)
        </Button>
      </DialogActions>
    </Dialog>
  );
}
