import { LoadingButton } from "@mui/lab";
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Grid, ListItemText } from "@mui/material";
import { differenceInMinutes } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DATE_TIME_FORMAT, ERROR, SUCCESS, WARNING } from "../../app/constants/common";
import { serverDate, uiToServer } from "../../app/utils/utilityFunctions";
import { GridLabelValueUI, Transition } from "../../components/ui/UiUtils";
import { useResolveOvertimeMutation } from "../../hooks/ApiHooks";
import { sectionUiLookBase } from "../../styled/inlineCssStyles";
import { setAlert } from "../Alerts/slice/alertSlice";
import { findEmployeeOptionById } from "../AppSettings/appSlice";
import { overtimeAttendanceStatusMapping } from "./config/attendanceUtils";
import { ALERT_MESSAGES, DIALOG_FIELDS, DIALOG_INITIAL_STATE, OVERTIME_ACTIONS } from "./config/resolveOvertimeConfig";

export default function ResolveOvertimeDialog({ dialogProps, setDialogProps }) {
  const { triggerMutation, isError, isLoading, data, error } = useResolveOvertimeMutation();
  const dispatch = useDispatch();
  const [status, setStatus] = useState();
  const { row, open } = dialogProps;
  const isPending = row?.status === "PDG";
  const { name: employeeName } = useSelector((state) => findEmployeeOptionById(state, row.ern));
  const [resolvedData, setResolvedData] = useState({
    ot_id: row?.ot_id,
    remark: row?.appr_remark,
    start_time: row?.start_time,
    end_time: row?.end_time,
  });
  const [totalDuration, setTotalDuration] = useState(row?.durationMinutes);

  const onHandleClose = () => setDialogProps((prev) => ({ ...prev, row: null, open: false }));
  const onChangeData = useCallback(
    (id) => (data) =>
      setResolvedData((prev) => ({
        ...prev,
        [id]: id === "start_time" || id === "end_time" ? uiToServer(data, DATE_TIME_FORMAT.SERVER_TIME) : data,
      })),
    []
  );

  const handleSubmit = (reqStatus) => {
    setStatus(reqStatus);
    if (row.start_time === resolvedData.start_time) delete resolvedData.start_time;
    if (row.end_time === resolvedData.end_time) delete resolvedData.end_time;
    triggerMutation({ ...resolvedData, action: reqStatus });
  };

  useEffect(() => {
    if (resolvedData.start_time && resolvedData.end_time)
      setTotalDuration(differenceInMinutes(serverDate(resolvedData.start_time), serverDate(resolvedData.end_time)));
  }, [resolvedData.start_time, resolvedData.end_time]);

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(setAlert(ALERT_MESSAGES.success.title, ALERT_MESSAGES.success.message, SUCCESS));
      setDialogProps(DIALOG_INITIAL_STATE);
    }
  }, [dispatch, isLoading, data, setDialogProps]);

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(
        setAlert(serviceUnavailable ? error.data.title : ALERT_MESSAGES.error.title, error.data.message, serviceUnavailable ? ERROR : WARNING)
      );
    }
  }, [dispatch, isError, error]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={!isLoading ? onHandleClose : null}
      TransitionComponent={Transition}
      PaperProps={{ sx: sectionUiLookBase }}
    >
      <DialogTitle sx={{ fontWeight: 500 }} color="primary" component="div">
        <ListItemText
          primary={`Overtime For Employee : ${row?.ern}`}
          secondary={`Status: ${overtimeAttendanceStatusMapping[row?.status]?.label}`}
          primaryTypographyProps={{ variant: "h6", fontWeight: 500 }}
          secondaryTypographyProps={{
            variant: "body1",
            fontWeight: 500,
            sx: { color: `${overtimeAttendanceStatusMapping[row?.status]?.color}.main` },
          }}
        />
      </DialogTitle>
      <DialogContent sx={{ py: 2, px: 3 }}>
        <Grid container spacing={2}>
          {DIALOG_FIELDS.map((field) => (
            <Grid item xs={12} key={field.id}>
              <GridLabelValueUI label={field.label} valueSx={{ "& .MuiFormControl-root": { m: 0 } }}>
                {field.id === "overtime_duration"
                  ? field.renderData(totalDuration, { row, resolvedData })
                  : field.renderData(
                      field.id === "employeeName" ? employeeName : row?.[field.id],
                      { onChange: (value) => onChangeData(field.id)(value) },
                      isPending && field.editable
                    )}
              </GridLabelValueUI>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between", px: 3, pb: 2, "& .MuiButton-root": { fontSize: "0.9rem" } }}>
        <Button variant="contained" onClick={onHandleClose} disabled={isLoading}>
          Close
        </Button>
        {!!isPending && (
          <ButtonGroup variant="outlined">
            {OVERTIME_ACTIONS.map((action) => (
              <LoadingButton
                key={action.id}
                color={action.color}
                startIcon={<action.icon />}
                onClick={() => handleSubmit(action.id)}
                loading={status === action.id && isLoading}
                disabled={isLoading}
              >
                {action.label}
              </LoadingButton>
            ))}
          </ButtonGroup>
        )}
      </DialogActions>
    </Dialog>
  );
}
