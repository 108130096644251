import { Delete, DynamicFeed, KeyboardArrowDown } from "@mui/icons-material";
import { AccordionDetails, Button, Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, createElement, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useExportData } from "../../hooks/valueProviderHooks";
import { MuiAccordion, MuiAccordionSummary } from "../../styled/accordionStyles";
import { MuiDropDownMenu } from "../../styled/commonStyles";
import { exportAccordionButtonDropdownStyles } from "../../styled/inlineCssStyles";
import { makeSelectRowsBasedOnOption, selectSelectedRowsCount } from "../slices/advancedTable";
import { ConfirmationDialog, LoadingProgress } from "./UiUtils";

const ExportDataTypeMenu = ({ rows, exportTableId, isDisabled, exportOption }) => {
  const { onClickExportReport, isExporting } = useExportData(exportTableId, exportOption.id);
  return (
    <MenuItem disableRipple onClick={isExporting ? null : () => onClickExportReport(rows)} disabled={isDisabled} sx={{ alignItems: "center" }}>
      {!isExporting ? (
        <Fragment>
          <ListItemIcon>{exportOption.icon}</ListItemIcon>
          <ListItemText primary={exportOption.label} />
        </Fragment>
      ) : (
        <LoadingProgress size={28} sx={{ my: 0.5 }} />
      )}
    </MenuItem>
  );
};

const ExportMenuOptions = ({ menuOption, activeId, setActiveId, exportTableId }) => {
  const rows = useSelector((state) => makeSelectRowsBasedOnOption(state, activeId));
  const isActive = menuOption.id === activeId;
  return (
    <MuiAccordion
      component="li"
      square
      expanded={isActive}
      onChange={() => setActiveId(isActive ? null : menuOption.id)}
      nestedSvgColor={(theme) => theme.palette.text.primary}
      sx={exportAccordionButtonDropdownStyles}
    >
      <MuiAccordionSummary aria-controls={`${menuOption.id}-bh-content`} id={`${menuOption.id}-bh-header`}>
        {menuOption.icon}
        <Box component="span" sx={{ mx: 1 }}>
          {menuOption.label}
        </Box>
      </MuiAccordionSummary>
      <AccordionDetails>
        <MenuList>
          {menuOption.exportTypeOptions.map((subMenuOption) => (
            <ExportDataTypeMenu
              rows={rows}
              isDisabled={rows.length === 0}
              key={subMenuOption.id}
              exportOption={subMenuOption}
              exportTableId={exportTableId}
            />
          ))}
        </MenuList>
      </AccordionDetails>
    </MuiAccordion>
  );
};

const DeleteRowItemsButton = ({ deleteHookWithParams: [useDeleteHook, useParams], refetchData, onClickCloseDropdown }) => {
  const selectedRowLength = useSelector(selectSelectedRowsCount);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteApi, isLoading, isSuccess] = useDeleteHook(...useParams);

  useEffect(() => {
    if (isSuccess) {
      setOpenConfirmDialog(false);
      onClickCloseDropdown();
      refetchData();
    }
  }, [refetchData, isSuccess, onClickCloseDropdown]);
  return (
    <Fragment>
      <MenuItem disableRipple sx={{ my: 1.5 }} onClick={() => setOpenConfirmDialog(true)} disabled={selectedRowLength === 0}>
        <Delete color="error" />
        <Typography sx={{ fontSize: "14px", fontWeight: "500", color: "error.main", mx: 1 }} component="span">
          Delete Selected Rows
        </Typography>
      </MenuItem>
      <ConfirmationDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        title="Confirm Selected Row(s) Deletion"
        subTitle="This action is irreversible. Please confirm to proceed with the deletion of selected rows. Take a moment to make sure everything's in order."
        onCancelTitle="Go Back"
        onSubmitTitle="Delete Anyway"
        loading={isLoading}
        onSubmit={deleteApi}
      />
    </Fragment>
  );
};

export default function ExportDataDropDownMenu({ exportButtonConfig, deleteRowsConfig, refetchFn }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeId, setActiveId] = useState(null);

  const handleClose = useCallback(() => {
    setActiveId(null);
    setAnchorEl(null);
  }, []);

  return (
    <Fragment>
      <Button
        id="export-data-btn"
        aria-controls={anchorEl ? "export-data-btn" : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={(event) => setAnchorEl(event.currentTarget)}
        startIcon={<DynamicFeed />}
        endIcon={<KeyboardArrowDown />}
        sx={{ fontWeight: 600, mr: 1 }}
      >
        Table Actions
      </Button>
      <MuiDropDownMenu
        id="export-data-menu"
        MenuListProps={{
          "aria-labelledby": "export-data-btn",
        }}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {!!exportButtonConfig.display &&
          (exportButtonConfig.exportOptions || []).map((option) => (
            <ExportMenuOptions
              key={option.id}
              menuOption={option}
              activeId={activeId}
              setActiveId={setActiveId}
              exportTableId={exportButtonConfig?.exportTableId}
            />
          ))}
        {exportButtonConfig.extraExportOptions?.map((Components, index) =>
          createElement(Components, { key: index, exportTableId: exportButtonConfig?.exportTableId, onClose: handleClose })
        )}
        {!!exportButtonConfig.display && !!deleteRowsConfig.display && <Divider />}
        {!!deleteRowsConfig.display && (
          <DeleteRowItemsButton
            onClickCloseDropdown={handleClose}
            refetchData={refetchFn}
            deleteHookWithParams={deleteRowsConfig.deleteHookWithParams}
          />
        )}
      </MuiDropDownMenu>
    </Fragment>
  );
}
