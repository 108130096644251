import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import XLSX from "xlsx-js-style";

const formatCellValue = (value) => {
  if (!value) return "";
  if (typeof value === "object" && value.name) return value.name;
  if (Array.isArray(value)) return value.join(", ");
  if (typeof value === "string") {
    return value
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/<[^>]*>/g, "")
      .replace(/\n/g, " ");
  }
  return String(value);
};

// XLSX Utility functions
/**
 * Applies header styles to worksheet
 * @param {object} ws - XLSX worksheet
 * @param {array} headers - Array of header names
 * @param {object} headerStyles - Style definitions for headers
 */
const applyHeaderStyles = (ws, headers, headerStyles) => {
  if (!headerStyles) return;

  const headerStyle = {
    fill: {
      fgColor: { rgb: headerStyles.background_color?.replace("#", "") },
    },
    font: {
      bold: headerStyles.font_weight === "bold",
      color: { rgb: headerStyles.font_color?.replace("#", "") },
    },
    alignment: {
      horizontal: headerStyles.text_align,
      vertical: headerStyles.vertical_alignment,
      wrapText: headerStyles.text_wrap,
    },
    border: {
      top: { style: headerStyles.border_style, color: { rgb: headerStyles.border_color?.replace("#", "") } },
      bottom: { style: headerStyles.border_style, color: { rgb: headerStyles.border_color?.replace("#", "") } },
      left: { style: headerStyles.border_style, color: { rgb: headerStyles.border_color?.replace("#", "") } },
      right: { style: headerStyles.border_style, color: { rgb: headerStyles.border_color?.replace("#", "") } },
    },
  };

  headers.forEach((_, index) => {
    const cellRef = XLSX.utils.encode_cell({ r: 0, c: index });
    if (!ws[cellRef]) ws[cellRef] = { v: headers[index] }; // Ensure the cell exists with a value
    ws[cellRef].s = headerStyle; // Apply styles
  });
};

const populateDataWithStyles = (ws, sheetData, columnStyles) => {
  sheetData.data.forEach((row, rowIndex) => {
    sheetData.headers.forEach((header, colIndex) => {
      const cellRef = XLSX.utils.encode_cell({ r: rowIndex + 1, c: colIndex });
      if (!ws[cellRef]) ws[cellRef] = { v: formatCellValue(row[header]) };

      // Apply column styles if available
      if (columnStyles?.[header]) {
        const cellStyle = columnStyles[header];
        ws[cellRef].s = {
          font: { bold: cellStyle.font_weight === "bold" },
          ...(cellStyle.background_color && {
            fill: {
              fgColor: { rgb: cellStyle.background_color?.replace("#", "") },
            },
          }),
          alignment: {
            horizontal: cellStyle.text_align || "left",
            vertical: cellStyle.vertical_alignment || "top",
            wrapText: cellStyle.text_wrap || false,
          },
          ...(cellStyle.border_style && {
            border: {
              top: { style: cellStyle.border_style, color: { rgb: cellStyle.border_color?.replace("#", "") } },
              bottom: { style: cellStyle.border_style, color: { rgb: cellStyle.border_color?.replace("#", "") } },
              left: { style: cellStyle.border_style, color: { rgb: cellStyle.border_color?.replace("#", "") } },
              right: { style: cellStyle.border_style, color: { rgb: cellStyle.border_color?.replace("#", "") } },
            },
          }),
        };
      }
    });
  });
};

const splitIntoChunks = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const createTableForColumnSet = (columnSet, sheet, pdf, startY) => {
  // Prepare data for this column set
  const tableData = sheet.data.map((row) =>
    columnSet.map((header) => ({
      content: formatCellValue(row[header]),
      raw: row[header],
    }))
  );

  // Calculate column width (accounting for margins)
  const pageWidth = pdf.internal.pageSize.width;
  const usableWidth = pageWidth - 40; // 20pt margin on each side
  const columnWidth = usableWidth / columnSet.length;

  const tableConfig = {
    head: [columnSet],
    body: tableData,
    startY: startY + 15,
    theme: "grid",
    styles: {
      fontSize: 9,
      cellPadding: 3,
      lineWidth: 0.1,
      minCellHeight: 10,
      valign: "middle",
    },
    headStyles: {
      fillColor: [240, 240, 240],
      textColor: [0, 0, 0],
      fontSize: 10,
      fontStyle: "bold",
      halign: "center",
    },
    columnStyles: columnSet.reduce((styles, _, index) => {
      styles[index] = {
        cellWidth: columnWidth,
        halign: "left",
      };
      return styles;
    }, {}),
    didParseCell: (cell) => {
      // Apply styles from the sheet if available
      if (sheet.styles) {
        const { row, column, section } = cell;

        if (section === "body") {
          const columnName = columnSet[column.index];
          const cellValue = row.raw[column.index]?.raw;

          // Apply column-specific styles
          if (sheet.styles.columns?.[columnName]) {
            const colStyle = sheet.styles.columns[columnName];
            if (colStyle.background_color?.[cellValue]) {
              cell.styles.fillColor = colStyle.background_color[cellValue].replace("#", "");
            }
            if (colStyle.font_weight?.[cellValue]) {
              cell.styles.fontStyle = colStyle.font_weight[cellValue];
            }
          }

          // Apply row-level styles
          if (sheet.styles.rows?.conditions) {
            sheet.styles.rows.conditions.forEach((condition) => {
              const rowValue = row.raw[columnSet.indexOf(condition.column)]?.raw;
              if (rowValue === condition.value) {
                cell.styles.fillColor = condition.style.background_color.replace("#", "");
              }
            });
          }

          cell.text = cell.raw?.content || "";
        }
      }
    },
    didDrawPage: () => {
      pdf.setFontSize(12);
      pdf.text(sheet.sheet_name, 14, 15);

      const pageNumber = pdf.internal.getNumberOfPages();
      pdf.setFontSize(8);
      pdf.text(`Page ${pageNumber}`, pdf.internal.pageSize.width - 20, pdf.internal.pageSize.height - 10);
    },
  };

  pdf.autoTable(tableConfig);
  return pdf.previousAutoTable.finalY;
};

const exportToPDF = (data) => {
  const pdf = new jsPDF({
    orientation: "landscape",
    unit: "pt",
    format: "a3",
  });

  data.sheets.forEach((sheet, sheetIndex) => {
    const columnGroups = splitIntoChunks(sheet.headers, 8);

    columnGroups.forEach((columnGroup, groupIndex) => {
      if (groupIndex > 0 || sheetIndex > 0) {
        pdf.addPage();
      }

      createTableForColumnSet(columnGroup, sheet, pdf, 20);
    });
  });

  return pdf;
};

const downloadFileForExport = (fileName, blob) => {
  const url = URL.createObjectURL(blob);
  saveAs(url, fileName);
  URL.revokeObjectURL(url);
};

export const exportDocument = (data, type) => {
  let blobURL;
  if (type === "export-csv") {
    const wb = XLSX.utils.book_new();
    data.sheets.forEach((sheet) => {
      const ws = XLSX.utils.json_to_sheet(sheet.data, { header: sheet.headers });
      applyHeaderStyles(ws, sheet.headers, sheet.styles?.headers);
      populateDataWithStyles(ws, sheet, sheet.styles?.columns);
      XLSX.utils.book_append_sheet(wb, ws, sheet.sheet_name);
    });

    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      bookSST: false,
      compression: true,
    });

    blobURL = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
  } else if (type === "export-pdf") {
    const pdf = exportToPDF(data);
    blobURL = new Blob([pdf.output("blob")], { type: "application/pdf" });
  }

  if (blobURL) {
    downloadFileForExport(data.file_name, blobURL);
    return;
  }

  throw new Error(`Unsupported export type: ${type}`);
};
