import { AccessTimeFilled } from "@mui/icons-material";
import { Box, InputAdornment, ListItemAvatar, ListItemText, Stack } from "@mui/material";
import { Fragment } from "react";
import { DATE_TIME_FORMAT } from "../../../app/constants/common";
import { SERVER_FIELD_TYPE } from "../../../app/constants/config/DynamicFields";
import { TASK_FIELDS } from "../../../app/constants/config/systemTasks";
import { getAdjustedColorForContrast, serverToUi } from "../../../app/utils/utilityFunctions";
import ReadOnlyTextEditor from "../../../components/RichTextEditor/ReadOnlyEditor";
import { EmployeeAvatar } from "../../../components/ui/UiUtils";
import { StyledChip } from "../../../styled/tableStyles";

const tasksFieldConfigData = {
  [TASK_FIELDS.TASK.ID]: {
    id: TASK_FIELDS.TASK.ID,
    label: TASK_FIELDS.TASK.LABEL,
    fieldType: SERVER_FIELD_TYPE.TEXT,
    alternateValue: TASK_FIELDS.TASK.ALTERNATE,
    valueProviderProps: {},
    textFieldProps: {},
  },
  [TASK_FIELDS.CUSTOMER.ID]: {
    id: TASK_FIELDS.CUSTOMER.ID,
    label: TASK_FIELDS.CUSTOMER.LABEL,
    fieldType: SERVER_FIELD_TYPE.SELECT,
    alternateValue: TASK_FIELDS.CUSTOMER.ALTERNATE,
    valueProviderProps: {
      dependentFields: [TASK_FIELDS.STORE.ID, TASK_FIELDS.EQUIPMENT.ID],
      fetchConfigId: TASK_FIELDS.CUSTOMER.LIST_CONFIG_ID,
    },
    renderData: (options, value) => options.find((option) => option[TASK_FIELDS.CUSTOMER.CONFIG_ID] === value)?.cust_name,
    getFromValue: (options, value) => options.find((option) => option[TASK_FIELDS.CUSTOMER.CONFIG_ID] === value),
    setToValue: (option) => option?.[TASK_FIELDS.CUSTOMER.CONFIG_ID] || "",
    textFieldProps: {
      id: TASK_FIELDS.CUSTOMER.ID,
      name: TASK_FIELDS.CUSTOMER.ID,
      placeholder: TASK_FIELDS.CUSTOMER.PLACEHOLDER,
      InputProps: {
        startAdornment: <InputAdornment position="start">{TASK_FIELDS.CUSTOMER.START_ICON}</InputAdornment>,
      },
      uniqueKeyId: TASK_FIELDS.CUSTOMER.CONFIG_ID,
      getOptionLabel: (option) => option.cust_name,
      filterFunction: ({ [TASK_FIELDS.CUSTOMER.CONFIG_ID]: customerId, cust_name: customerName }, inputValue) =>
        [customerId, customerName].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
      renderOptionComponent: ({ [TASK_FIELDS.CUSTOMER.CONFIG_ID]: customerId, cust_name: customerName }) => (
        <ListItemText primary={customerName} secondary={customerId} />
      ),
      isOptionEqualToValue: (option, value) => option[TASK_FIELDS.CUSTOMER.CONFIG_ID] === value[TASK_FIELDS.CUSTOMER.CONFIG_ID],
      noOptionsText: "No Customers Available",
    },
  },
  [TASK_FIELDS.STORE.ID]: {
    id: TASK_FIELDS.STORE.ID,
    label: TASK_FIELDS.STORE.LABEL,
    fieldType: SERVER_FIELD_TYPE.SELECT,
    alternateValue: TASK_FIELDS.STORE.ALTERNATE,
    renderData: (options, value) => {
      const store = options.find((option) => option[TASK_FIELDS.STORE.CONFIG_ID] === value);
      return `${store.store_name} [${store.store_id}]`;
    },
    valueProviderProps: {
      parentFieldId: TASK_FIELDS.CUSTOMER.ID,
      dependentFields: [TASK_FIELDS.EQUIPMENT.ID],
      filterBooleanFunction: (customer, parentFieldValue) => parentFieldValue === customer[TASK_FIELDS.CUSTOMER.CONFIG_ID],
      fetchConfigId: TASK_FIELDS.STORE.LIST_CONFIG_ID,
    },
    getFromValue: (options, value) => options.find((option) => option[TASK_FIELDS.STORE.CONFIG_ID] === value),
    setToValue: (option) => option?.[TASK_FIELDS.STORE.CONFIG_ID] || "",
    textFieldProps: {
      id: TASK_FIELDS.STORE.ID,
      name: TASK_FIELDS.STORE.ID,
      placeholder: TASK_FIELDS.STORE.PLACEHOLDER,
      InputProps: {
        startAdornment: <InputAdornment position="start">{TASK_FIELDS.STORE.START_ICON}</InputAdornment>,
      },
      uniqueKeyId: TASK_FIELDS.STORE.CONFIG_ID,
      getOptionLabel: (option) => option.store_name,
      filterFunction: ({ store_id_ext: storeExtId, store_name: storeName }, inputValue) =>
        [storeExtId, storeName].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
      renderOptionComponent: ({ store_id_ext: storeExtId, store_name: storeName }) => <ListItemText primary={storeName} secondary={storeExtId} />,
      isOptionEqualToValue: (option, value) => option[TASK_FIELDS.STORE.CONFIG_ID] === value[TASK_FIELDS.STORE.CONFIG_ID],
      noOptionsText: "No Stores Available",
    },
  },
  [TASK_FIELDS.EQUIPMENT.ID]: {
    id: TASK_FIELDS.EQUIPMENT.ID,
    label: TASK_FIELDS.EQUIPMENT.LABEL,
    fieldType: SERVER_FIELD_TYPE.SELECT,
    alternateValue: TASK_FIELDS.EQUIPMENT.ALTERNATE,
    valueProviderProps: {
      parentFieldId: TASK_FIELDS.STORE.ID,
      filterBooleanFunction: ({ eq_store_id }, parentFieldValue) => eq_store_id === parentFieldValue,
      fetchConfigId: TASK_FIELDS.EQUIPMENT.LIST_CONFIG_ID,
    },
    renderData: (options, value) => {
      const equipment = options.find((option) => option[TASK_FIELDS.EQUIPMENT.CONFIG_ID] === value);
      return `${equipment.eq_model_name} [${equipment.eq_serial}]`;
    },
    getFromValue: (options, value) => options.find((option) => option[TASK_FIELDS.EQUIPMENT.CONFIG_ID] === value),
    setToValue: (option) => option?.[TASK_FIELDS.EQUIPMENT.CONFIG_ID] || "",
    textFieldProps: {
      id: TASK_FIELDS.EQUIPMENT.ID,
      name: TASK_FIELDS.EQUIPMENT.ID,
      placeholder: TASK_FIELDS.EQUIPMENT.PLACEHOLDER,
      InputProps: {
        startAdornment: <InputAdornment position="start">{TASK_FIELDS.EQUIPMENT.START_ICON}</InputAdornment>,
      },
      uniqueKeyId: TASK_FIELDS.EQUIPMENT.CONFIG_ID,
      getOptionLabel: (option) => option.eq_model_name,
      filterFunction: ({ eq_serial, eq_model_name: eqModalName }, inputValue) =>
        [eq_serial, eqModalName].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
      renderOptionComponent: ({ eq_serial, eq_model_name: eqModalName }) => <ListItemText primary={eqModalName} secondary={eq_serial} />,
      isOptionEqualToValue: (option, value) => option[TASK_FIELDS.EQUIPMENT.CONFIG_ID] === value[TASK_FIELDS.EQUIPMENT.CONFIG_ID],
      noOptionsText: "No Equipments Available",
    },
  },
  [TASK_FIELDS.CUSTOMER_NUMBER.ID]: {
    id: TASK_FIELDS.CUSTOMER_NUMBER.ID,
    label: TASK_FIELDS.CUSTOMER_NUMBER.LABEL,
    fieldType: SERVER_FIELD_TYPE.TEXT,
    alternateValue: TASK_FIELDS.CUSTOMER_NUMBER.ALTERNATE,
    valueProviderProps: {},
    textFieldProps: {
      id: TASK_FIELDS.CUSTOMER_NUMBER.ID,
      name: TASK_FIELDS.CUSTOMER_NUMBER.ID,
      placeholder: TASK_FIELDS.CUSTOMER_NUMBER.PLACEHOLDER,
      InputProps: {
        startAdornment: <InputAdornment position="start">{TASK_FIELDS.CUSTOMER_NUMBER.START_ICON}</InputAdornment>,
      },
    },
  },
  [TASK_FIELDS.TITLE.ID]: {
    id: TASK_FIELDS.TITLE.ID,
    label: TASK_FIELDS.TITLE.LABEL,
    fieldType: SERVER_FIELD_TYPE.TEXT,
    alternateValue: TASK_FIELDS.TITLE.ALTERNATE,
    valueProviderProps: {},
    textFieldProps: {
      id: TASK_FIELDS.TITLE.ID,
      name: TASK_FIELDS.TITLE.ID,
      placeholder: TASK_FIELDS.TITLE.PLACEHOLDER,
      InputProps: {
        startAdornment: <InputAdornment position="start">{TASK_FIELDS.TITLE.START_ICON}</InputAdornment>,
      },
    },
  },
  [TASK_FIELDS.PARENT_ID.ID]: {
    id: TASK_FIELDS.PARENT_ID.ID,
    label: TASK_FIELDS.PARENT_ID.LABEL,
    fieldType: SERVER_FIELD_TYPE.SELECT,
    alternateValue: TASK_FIELDS.PARENT_ID.ALTERNATE,
    valueProviderProps: {
      fetchConfigId: TASK_FIELDS.PARENT_ID.LIST_CONFIG_ID,
      filterExcludedBoolean: (option, excludedValues) => excludedValues.includes(option[TASK_FIELDS.TASK.ID]),
    },
    renderData: (options, value) => options.find((option) => option[TASK_FIELDS.TASK.ID] === value)?.task_title,
    getFromValue: (options, value) => options.find((option) => option[TASK_FIELDS.TASK.ID] === value),
    setToValue: (option) => option?.[TASK_FIELDS.TASK.ID] || "",
    textFieldProps: {
      id: TASK_FIELDS.PARENT_ID.ID,
      name: TASK_FIELDS.PARENT_ID.ID,
      placeholder: TASK_FIELDS.PARENT_ID.PLACEHOLDER,
      InputProps: {
        startAdornment: <InputAdornment position="start">{TASK_FIELDS.PARENT_ID.START_ICON}</InputAdornment>,
      },
      uniqueKeyId: TASK_FIELDS.TASK.ID,
      getOptionLabel: (option) => option[TASK_FIELDS.TASK.ID],
      filterFunction: ({ [TASK_FIELDS.TASK.ID]: taskId, task_title: taskTitle }, inputValue) =>
        [taskId, taskTitle].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
      renderOptionComponent: ({ [TASK_FIELDS.TASK.ID]: taskId, task_title: taskTitle }) => <ListItemText primary={taskTitle} secondary={taskId} />,
      isOptionEqualToValue: (option, value) => option[TASK_FIELDS.TASK.ID] === value[TASK_FIELDS.TASK.ID],
      noOptionsText: "No Tasks Available",
    },
  },
  [TASK_FIELDS.TYPE.ID]: {
    id: TASK_FIELDS.TYPE.ID,
    label: TASK_FIELDS.TYPE.LABEL,
    fieldType: SERVER_FIELD_TYPE.SELECT,
    alternateValue: TASK_FIELDS.TYPE.ALTERNATE,
    renderData: (options, value) => options.find((option) => option.map_key === value)?.map_val,
    valueProviderProps: {
      fetchConfigId: TASK_FIELDS.TYPE.LIST_CONFIG_ID,
    },
    getFromValue: (options, value) => options.find((option) => option.map_key === value),
    setToValue: (option) => option?.map_key || "",
    textFieldProps: {
      id: TASK_FIELDS.TYPE.ID,
      name: TASK_FIELDS.TYPE.ID,
      placeholder: TASK_FIELDS.TYPE.PLACEHOLDER,
      InputProps: {
        startAdornment: <InputAdornment position="start">{TASK_FIELDS.TYPE.START_ICON}</InputAdornment>,
      },
      uniqueKeyId: "map_key",
      getOptionLabel: (option) => option.map_val,
      filterFunction: ({ map_key, map_val }, inputValue) =>
        [map_key, map_val].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
      renderOptionComponent: ({ map_key, map_val }) => <ListItemText primary={map_val} secondary={map_key} />,
      isOptionEqualToValue: (option, value) => option.map_key === value.map_key,
      noOptionsText: "No Types Available",
    },
  },
  [TASK_FIELDS.STATUS.ID]: {
    id: TASK_FIELDS.STATUS.ID,
    label: TASK_FIELDS.STATUS.LABEL,
    fieldType: SERVER_FIELD_TYPE.SELECT,
    alternateValue: TASK_FIELDS.STATUS.ALTERNATE,
    renderData: (options, value) => options.find((option) => option.map_key === value)?.map_val,
    valueProviderProps: {
      fetchConfigId: TASK_FIELDS.STATUS.LIST_CONFIG_ID,
      findBooleanFunction: (option, dataValue) => dataValue === option["map_key"],
    },
    getFromValue: (options, value) => options.find((option) => option.map_key === value),
    setToValue: (option) => option?.map_key || "",
    textFieldProps: {
      id: TASK_FIELDS.STATUS.ID,
      name: TASK_FIELDS.STATUS.ID,
      placeholder: TASK_FIELDS.STATUS.PLACEHOLDER,
      InputProps: {
        startAdornment: <InputAdornment position="start">{TASK_FIELDS.STATUS.START_ICON}</InputAdornment>,
      },
      uniqueKeyId: "map_key",
      getOptionLabel: (option) => option.map_val,
      filterFunction: ({ map_key, map_val }, inputValue) =>
        [map_key, map_val].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
      renderOptionComponent: ({ map_key, map_val }) => <ListItemText primary={map_val} secondary={map_key} />,
      isOptionEqualToValue: (option, value) => option.map_key === value.map_key,
      noOptionsText: "No Statuses Available",
    },
  },
  [TASK_FIELDS.PRIORITY.ID]: {
    id: TASK_FIELDS.PRIORITY.ID,
    label: TASK_FIELDS.PRIORITY.LABEL,
    fieldType: SERVER_FIELD_TYPE.SELECT,
    alternateValue: TASK_FIELDS.PRIORITY.ALTERNATE,
    renderData: (options, value) => options.find((option) => option.map_key === value)?.map_val,
    valueProviderProps: {
      fetchConfigId: TASK_FIELDS.PRIORITY.LIST_CONFIG_ID,
    },
    getFromValue: (options, value) => options.find((option) => option.map_key === value),
    setToValue: (option) => option?.map_key || "",
    textFieldProps: {
      id: TASK_FIELDS.PRIORITY.ID,
      name: TASK_FIELDS.PRIORITY.ID,
      placeholder: TASK_FIELDS.PRIORITY.PLACEHOLDER,
      InputProps: {
        startAdornment: <InputAdornment position="start">{TASK_FIELDS.PRIORITY.START_ICON}</InputAdornment>,
      },
      uniqueKeyId: "map_key",
      getOptionLabel: (option) => option.map_val,
      filterFunction: ({ map_key, map_val }, inputValue) =>
        [map_key, map_val].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
      renderOptionComponent: ({ map_key, map_val }) => <ListItemText primary={map_val} secondary={map_key} />,
      isOptionEqualToValue: (option, value) => option.map_key === value.map_key,
      noOptionsText: "No Priority Types Available",
    },
  },
  [TASK_FIELDS.OWNER.ID]: {
    id: TASK_FIELDS.OWNER.ID,
    label: TASK_FIELDS.OWNER.LABEL,
    fieldType: SERVER_FIELD_TYPE.SELECT,
    alternateValue: TASK_FIELDS.OWNER.ALTERNATE,
    renderData: (options, value) => {
      const option = options.find((employee) => value === employee[TASK_FIELDS.OWNER.CONFIG_ID]);
      return !!option && `${option.name} [${option.ern}]`;
    },
    valueProviderProps: {
      fetchConfigId: TASK_FIELDS.OWNER.LIST_CONFIG_ID,
    },
    getFromValue: (options, value) => options.find((employee) => value === employee[TASK_FIELDS.OWNER.CONFIG_ID]),
    setToValue: (employee) => employee[TASK_FIELDS.OWNER.CONFIG_ID] || "",
    textFieldProps: {
      id: TASK_FIELDS.OWNER.ID,
      name: TASK_FIELDS.OWNER.ID,
      placeholder: TASK_FIELDS.OWNER.PLACEHOLDER,
      InputProps: {
        startAdornment: <InputAdornment position="start">{TASK_FIELDS.OWNER.START_ICON}</InputAdornment>,
      },
      uniqueKeyId: TASK_FIELDS.OWNER.CONFIG_ID,
      getOptionLabel: (option) => option.name,
      filterFunction: ({ [TASK_FIELDS.OWNER.CONFIG_ID]: employeeId, name: employeeName }, inputValue) =>
        [employeeId, employeeName].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
      renderOptionComponent: ({ [TASK_FIELDS.OWNER.CONFIG_ID]: employeeId, name: employeeName }) => (
        <Fragment>
          <ListItemAvatar>
            <EmployeeAvatar empId={employeeId} />
          </ListItemAvatar>
          <ListItemText primary={employeeName} secondary={employeeId} />
        </Fragment>
      ),
      isOptionEqualToValue: (option, value) => option[TASK_FIELDS.OWNER.CONFIG_ID] === value[TASK_FIELDS.OWNER.CONFIG_ID],
      noOptionsText: "No Employees Available",
    },
  },
  [TASK_FIELDS.ASSIGNED_EMPLOYEES.ID]: {
    id: TASK_FIELDS.ASSIGNED_EMPLOYEES.ID,
    label: TASK_FIELDS.ASSIGNED_EMPLOYEES.LABEL,
    fieldType: SERVER_FIELD_TYPE.SELECT,
    alternateValue: TASK_FIELDS.ASSIGNED_EMPLOYEES.ALTERNATE,
    /*
    Render Method is not for single employee display since Employees Assigned is an array but update task requires it as a string
    */
    renderData: (options = [], value = []) =>
      options
        .filter((option) => value.includes(option.ern))
        .map(({ [TASK_FIELDS.ASSIGNED_EMPLOYEES.CONFIG_ID]: employeeId, name: employeeName }) => `${employeeName} [${employeeId}]`)
        .join(", "),
    valueProviderProps: {
      fetchConfigId: TASK_FIELDS.ASSIGNED_EMPLOYEES.LIST_CONFIG_ID,
    },
    getFromValue: (options, value) => options.find((employee) => value === employee[TASK_FIELDS.ASSIGNED_EMPLOYEES.CONFIG_ID]),
    setToValue: (employee) => employee[TASK_FIELDS.ASSIGNED_EMPLOYEES.CONFIG_ID] || "",
    textFieldProps: {
      id: TASK_FIELDS.ASSIGNED_EMPLOYEES.ID,
      name: TASK_FIELDS.ASSIGNED_EMPLOYEES.ID,
      placeholder: TASK_FIELDS.ASSIGNED_EMPLOYEES.PLACEHOLDER,
      InputProps: {
        startAdornment: <InputAdornment position="start">{TASK_FIELDS.ASSIGNED_EMPLOYEES.START_ICON}</InputAdornment>,
      },
      uniqueKeyId: TASK_FIELDS.ASSIGNED_EMPLOYEES.CONFIG_ID,
      getOptionLabel: (option) => option.name,
      filterFunction: ({ [TASK_FIELDS.ASSIGNED_EMPLOYEES.CONFIG_ID]: employeeId, name: employeeName }, inputValue) =>
        [employeeId, employeeName].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
      renderOptionComponent: ({ [TASK_FIELDS.ASSIGNED_EMPLOYEES.CONFIG_ID]: employeeId, name: employeeName }) => (
        <Fragment>
          <ListItemAvatar>
            <EmployeeAvatar empId={employeeId} />
          </ListItemAvatar>
          <ListItemText primary={employeeName} secondary={employeeId} />
        </Fragment>
      ),
      isOptionEqualToValue: (option, value) => option[TASK_FIELDS.ASSIGNED_EMPLOYEES.CONFIG_ID] === value[TASK_FIELDS.ASSIGNED_EMPLOYEES.CONFIG_ID],
      noOptionsText: "No Employees Available",
    },
  },
  [TASK_FIELDS.EXECUTOR_EMPLOYEES.ID]: {
    id: TASK_FIELDS.EXECUTOR_EMPLOYEES.ID,
    label: TASK_FIELDS.EXECUTOR_EMPLOYEES.LABEL,
    fieldType: SERVER_FIELD_TYPE.SELECT,
    alternateValue: TASK_FIELDS.EXECUTOR_EMPLOYEES.ALTERNATE,
    renderData: (options, value = []) =>
      options
        .filter((employee) => value.includes(employee[TASK_FIELDS.EXECUTOR_EMPLOYEES.CONFIG_ID]))
        .map(({ [TASK_FIELDS.EXECUTOR_EMPLOYEES.CONFIG_ID]: employeeId, name: employeeName }) => `${employeeName} [${employeeId}]`)
        .join(", "),
    valueProviderProps: {
      fetchConfigId: TASK_FIELDS.EXECUTOR_EMPLOYEES.LIST_CONFIG_ID,
    },
    getFromValue: (options, value) => options.filter((employee) => value.includes(employee[TASK_FIELDS.EXECUTOR_EMPLOYEES.CONFIG_ID])),
    setToValue: (employees) => employees.map((employee) => employee[TASK_FIELDS.EXECUTOR_EMPLOYEES.CONFIG_ID]),
    textFieldProps: {
      id: TASK_FIELDS.EXECUTOR_EMPLOYEES.ID,
      name: TASK_FIELDS.EXECUTOR_EMPLOYEES.ID,
      placeholder: TASK_FIELDS.EXECUTOR_EMPLOYEES.PLACEHOLDER,
      InputProps: {
        startAdornment: <InputAdornment position="start">{TASK_FIELDS.EXECUTOR_EMPLOYEES.START_ICON}</InputAdornment>,
      },
      multiple: true,
      limitTags: 2,
      uniqueKeyId: TASK_FIELDS.EXECUTOR_EMPLOYEES.CONFIG_ID,
      getOptionLabel: (option) => option.name,
      filterFunction: ({ [TASK_FIELDS.EXECUTOR_EMPLOYEES.CONFIG_ID]: employeeId, name: employeeName }, inputValue) =>
        [employeeId, employeeName].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
      renderOptionComponent: ({ [TASK_FIELDS.EXECUTOR_EMPLOYEES.CONFIG_ID]: employeeId, name: employeeName }) => (
        <Fragment>
          <ListItemAvatar>
            <EmployeeAvatar empId={employeeId} />
          </ListItemAvatar>
          <ListItemText primary={employeeName} secondary={employeeId} />
        </Fragment>
      ),
      isOptionEqualToValue: (option, value) => option[TASK_FIELDS.EXECUTOR_EMPLOYEES.CONFIG_ID] === value[TASK_FIELDS.EXECUTOR_EMPLOYEES.CONFIG_ID],
      noOptionsText: "No Employees Available",
    },
  },
  [TASK_FIELDS.TAGS.ID]: {
    id: TASK_FIELDS.TAGS.ID,
    label: TASK_FIELDS.TAGS.LABEL,
    fieldType: SERVER_FIELD_TYPE.SELECT,
    alternateValue: TASK_FIELDS.TAGS.ALTERNATE,
    renderData: (options, value, theme) =>
      options
        .filter((tag) => value.includes(tag[TASK_FIELDS.TAGS.CONFIG_ID]))
        .map(({ [TASK_FIELDS.TAGS.CONFIG_ID]: tagId, tag_name: tagName, tag_colour: tagColor }) => {
          const [color, backgroundColor] = getAdjustedColorForContrast(tagColor, theme);
          return (
            <StyledChip
              key={tagId}
              minWidth="fit-content"
              color={color}
              backgroundColor={backgroundColor}
              label={tagName}
              sx={{ borderRadius: 1, borderColor: "transparent", mr: 0.5, mb: 0.5 }}
            />
          );
        }),
    valueProviderProps: {
      fetchConfigId: TASK_FIELDS.TAGS.LIST_CONFIG_ID,
    },
    getFromValue: (options, value) => options.filter((tag) => value.includes(tag[TASK_FIELDS.TAGS.CONFIG_ID])),
    setToValue: (tags) => tags.map((tag) => tag[TASK_FIELDS.TAGS.CONFIG_ID]),
    textFieldProps: {
      id: TASK_FIELDS.TAGS.ID,
      name: TASK_FIELDS.TAGS.ID,
      placeholder: TASK_FIELDS.TAGS.PLACEHOLDER,
      InputProps: {
        startAdornment: <InputAdornment position="start">{TASK_FIELDS.TAGS.START_ICON}</InputAdornment>,
      },
      multiple: true,
      limitTags: 2,
      uniqueKeyId: TASK_FIELDS.TAGS.CONFIG_ID,
      getOptionLabel: (option) => option.tag_name,
      filterFunction: ({ [TASK_FIELDS.TAGS.CONFIG_ID]: tagId, tag_name: tagName }, inputValue) =>
        [tagName, tagId].some((entity) => String(entity).toLowerCase().includes(inputValue.toLowerCase())),
      renderOptionComponent: ({ [TASK_FIELDS.TAGS.CONFIG_ID]: tagId, tag_name: tagName, tag_colour: tagColor, tag_desc: tagDesc }, theme) => (
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <ListItemText primary={tagName} secondary={tagDesc ? `${tagId} - ${tagDesc}` : tagId || ""} />
          <Box sx={{ height: 24, width: 24, borderRadius: 1, backgroundColor: getAdjustedColorForContrast(tagColor, theme)[0] }} />
        </Stack>
      ),
      isOptionEqualToValue: (option, value) => option[TASK_FIELDS.TAGS.CONFIG_ID] === value[TASK_FIELDS.TAGS.CONFIG_ID],
      noOptionsText: "No Tags Available",
    },
  },
  [TASK_FIELDS.DESCRIPTION.ID]: {
    id: TASK_FIELDS.DESCRIPTION.ID,
    label: TASK_FIELDS.DESCRIPTION.LABEL,
    fieldType: SERVER_FIELD_TYPE.MULTI_LINE,
    alternateValue: TASK_FIELDS.DESCRIPTION.ALTERNATE,
    renderData: (_, dataValue) => <ReadOnlyTextEditor content={dataValue} />,
    valueProviderProps: {},
    textFieldProps: {
      id: TASK_FIELDS.DESCRIPTION.ID,
      name: TASK_FIELDS.DESCRIPTION.ID,
      placeholder: TASK_FIELDS.DESCRIPTION.PLACEHOLDER,
      maxCharactersAllowed: 2000,
    },
  },
  [TASK_FIELDS.PROBLEM.ID]: {
    id: TASK_FIELDS.PROBLEM.ID,
    label: TASK_FIELDS.PROBLEM.LABEL,
    fieldType: SERVER_FIELD_TYPE.MULTI_LINE,
    alternateValue: TASK_FIELDS.PROBLEM.ALTERNATE,
    renderData: (_, dataValue) => <ReadOnlyTextEditor content={dataValue} />,
    valueProviderProps: {},
    textFieldProps: {
      id: TASK_FIELDS.PROBLEM.ID,
      name: TASK_FIELDS.PROBLEM.ID,
      placeholder: TASK_FIELDS.PROBLEM.PLACEHOLDER,
      maxHeight: "220px",
      maxCharactersAllowed: 400,
    },
  },
  [TASK_FIELDS.CAUSE.ID]: {
    id: TASK_FIELDS.CAUSE.ID,
    label: TASK_FIELDS.CAUSE.LABEL,
    fieldType: SERVER_FIELD_TYPE.MULTI_LINE,
    alternateValue: TASK_FIELDS.CAUSE.ALTERNATE,
    renderData: (_, dataValue) => <ReadOnlyTextEditor content={dataValue} />,
    valueProviderProps: {},
    textFieldProps: {
      id: TASK_FIELDS.CAUSE.ID,
      name: TASK_FIELDS.CAUSE.ID,
      placeholder: TASK_FIELDS.CAUSE.PLACEHOLDER,
      maxHeight: "220px",
      maxCharactersAllowed: 400,
    },
  },
  [TASK_FIELDS.REPAIR.ID]: {
    id: TASK_FIELDS.REPAIR.ID,
    label: TASK_FIELDS.REPAIR.LABEL,
    fieldType: SERVER_FIELD_TYPE.MULTI_LINE,
    alternateValue: TASK_FIELDS.REPAIR.ALTERNATE,
    renderData: (_, dataValue) => <ReadOnlyTextEditor content={dataValue} />,
    valueProviderProps: {},
    textFieldProps: {
      id: TASK_FIELDS.REPAIR.ID,
      name: TASK_FIELDS.REPAIR.ID,
      placeholder: TASK_FIELDS.REPAIR.PLACEHOLDER,
      maxHeight: "220px",
      maxCharactersAllowed: 1000,
    },
  },
  [TASK_FIELDS.RETAIN_PARTS.ID]: {
    id: TASK_FIELDS.RETAIN_PARTS.ID,
    label: TASK_FIELDS.RETAIN_PARTS.LABEL,
    fieldType: SERVER_FIELD_TYPE.CHECK_BOX,
    alternateValue: TASK_FIELDS.RETAIN_PARTS.ALTERNATE,
    renderData: (_, dataValue) => (dataValue === "Y" ? "Yes" : "No"),
    getFromValue: (value) => value === "Y",
    setToValue: (checkboxValue) => (checkboxValue ? "Y" : "N"),
    valueProviderProps: {},
    textFieldProps: {
      id: TASK_FIELDS.RETAIN_PARTS.ID,
      name: TASK_FIELDS.RETAIN_PARTS.ID,
      label: TASK_FIELDS.RETAIN_PARTS.LABEL,
    },
  },
  [TASK_FIELDS.OVERTIME.ID]: {
    id: TASK_FIELDS.OVERTIME.ID,
    label: TASK_FIELDS.OVERTIME.LABEL,
    fieldType: SERVER_FIELD_TYPE.TEXT,
    alternateValue: TASK_FIELDS.OVERTIME.ALTERNATE,
    valueProviderProps: {},
  },
  [TASK_FIELDS.TASK_ASSIGNED_DATE.ID]: {
    id: TASK_FIELDS.TASK_ASSIGNED_DATE.ID,
    label: TASK_FIELDS.TASK_ASSIGNED_DATE.LABEL,
    fieldType: SERVER_FIELD_TYPE.DATE_TMS8,
    alternateValue: TASK_FIELDS.TASK_ASSIGNED_DATE.ALTERNATE,
    renderData: (_, dataValue) => serverToUi(dataValue, DATE_TIME_FORMAT.UI_DATE_FORMAT),
    valueProviderProps: {},
    componentProps: {
      id: TASK_FIELDS.TASK_ASSIGNED_DATE.ID,
      slotProps: { inputAdornment: { position: "start" } },
      slots: { openPickerIcon: AccessTimeFilled },
      minDate: new Date(),
    },
  },
  [TASK_FIELDS.START_TIME.ID]: {
    id: TASK_FIELDS.START_TIME.ID,
    label: TASK_FIELDS.START_TIME.LABEL,
    fieldType: SERVER_FIELD_TYPE.DATE_TMS14,
    alternateValue: TASK_FIELDS.START_TIME.ALTERNATE,
    renderData: (_, dataValue) => serverToUi(dataValue, DATE_TIME_FORMAT.UI_FULL_FORMAT),
    valueProviderProps: {},
  },
  [TASK_FIELDS.END_TIME.ID]: {
    id: TASK_FIELDS.END_TIME.ID,
    label: TASK_FIELDS.END_TIME.LABEL,
    fieldType: SERVER_FIELD_TYPE.DATE_TMS14,
    alternateValue: TASK_FIELDS.END_TIME.ALTERNATE,
    renderData: (_, dataValue) => serverToUi(dataValue, DATE_TIME_FORMAT.UI_FULL_FORMAT),
    valueProviderProps: {},
  },
};

export const getConfigDataBy = (fields) =>
  fields.map(({ id, ...rest }, index) => ({ order: index, ...tasksFieldConfigData[id], ...rest })).sort((a, b) => a.order - b.order);
