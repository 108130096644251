import {
  Abc,
  AccessTimeFilled,
  AddTaskOutlined,
  Autorenew,
  ClearOutlined,
  ModeEditOutlined,
  NavigateBefore,
  NavigateNext,
  ReadMore,
  Search,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, InputAdornment, Paper, Stack, TextField, Typography, useTheme } from "@mui/material";
import { Fragment, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DATE_TIME_FORMAT, ERROR, SUCCESS, WARNING } from "../../../app/constants/common";
import { IS_DATE, IS_MULTI_SELECT, IS_SELECT, SERVER_FIELD_TYPE } from "../../../app/constants/config/DynamicFields";
import { routeTaskParam } from "../../../app/constants/routes";
import { parseFieldValue, serverToUi } from "../../../app/utils/utilityFunctions";
import { RenderOnDemandField } from "../../../components/ui/InputUI";
import { BlockerComponent, GridLabelValueUI, UiIconButton } from "../../../components/ui/UiUtils";
import { useUpdateExistingTaskMutation } from "../../../hooks/ApiHooks";
import { sectionUiLookBase } from "../../../styled/inlineCssStyles";
import { setAlert } from "../../Alerts/slice/alertSlice";
import { selectPresetConfig } from "../../AppSettings/appSlice";
import {
  clearAllTaskFormFields,
  selectAllTasksFieldsWithId,
  selectAreRequiredTaskFieldsFilled,
  selectIsAdditionalTaskFieldsEditModeOn,
  selectIsConfigDataLoaded,
  selectIsFieldEditable,
  selectIsFieldRequired,
  selectTaskFieldValue,
  setAdditionalTaskFieldsEditMode,
  setTasksFieldValue,
  setTasksFormConfig,
} from "../slice/taskSlice";

const EditChangesButtonGroup = memo(({ refetchData }) => {
  const { [routeTaskParam]: taskId } = useParams();
  const isEditModeOn = useSelector(selectIsAdditionalTaskFieldsEditModeOn);
  const areRequiredFieldsFilled = useSelector(selectAreRequiredTaskFieldsFilled);
  const updatedTaskData = useSelector(selectAllTasksFieldsWithId);
  const dispatch = useDispatch();
  const onChangeEdit = () => dispatch(setAdditionalTaskFieldsEditMode());
  const { triggerMutation, isLoading, isError, error, data } = useUpdateExistingTaskMutation();

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(setAlert(serviceUnavailable ? error.data.title : "Task Update Failed", error.data.message, serviceUnavailable ? ERROR : WARNING));
    }
  }, [dispatch, isError, error]);

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(clearAllTaskFormFields());
      dispatch(setAlert(`Additional Task Fields were updated successfully`, "Please wait while we fetch the updated data", SUCCESS));
      refetchData();
    }
  }, [dispatch, refetchData, isLoading, data]);

  const onSaveChanges = () => {
    triggerMutation(taskId, { task_add_config: updatedTaskData });
  };

  return (
    <Stack gap={1} direction="row" alignItems="center" sx={{ "& .MuiButton-root": { fontWeight: 600 } }}>
      <Button
        variant="outlined"
        color={isEditModeOn ? "error" : "primary"}
        startIcon={isEditModeOn ? <ClearOutlined /> : <ModeEditOutlined />}
        onClick={onChangeEdit}
      >
        {isEditModeOn ? "Cancel" : "Edit"}
      </Button>
      <LoadingButton
        loading={isLoading}
        variant="contained"
        disabled={!isEditModeOn || !areRequiredFieldsFilled}
        startIcon={<AddTaskOutlined />}
        onClick={onSaveChanges}
        sx={{ mr: -0.5 }}
      >
        Save Changes
      </LoadingButton>
      <UiIconButton title="Refetch Data" onClickIcon={refetchData} disabled={isLoading}>
        <Autorenew />
      </UiIconButton>
      <BlockerComponent when={isLoading || isEditModeOn} />
    </Stack>
  );
});

const RenderRawDataField = memo(({ field }) => {
  const theme = useTheme();
  const options = useSelector((state) => selectPresetConfig(state, field.valueProviderProps.fetchConfigId));
  return field.value?.length ? field.renderData?.(options, field.value, theme) || field.value : field.alternateValue;
});

const RenderInputDataField = memo(({ field }) => {
  const dispatch = useDispatch();
  const currentValue = useSelector((state) => selectTaskFieldValue(state, field.id));
  const onChange = useCallback(
    (input) => {
      const value = parseFieldValue(field.fieldType, input)[0];
      dispatch(setTasksFieldValue([field.id, undefined, value]));
    },
    [field.fieldType, field.id, dispatch]
  );
  return (
    <RenderOnDemandField
      field={field}
      fieldType={field.fieldType}
      fieldValue={currentValue}
      onChange={onChange}
      optionsData={field.optionsData}
      disableClearable={field.isRequired}
    />
  );
});

const RenderMandatoryLabelOnEdit = memo(({ fieldId }) => {
  const isFieldRequired = useSelector((state) => selectIsFieldRequired(state, fieldId));
  return (
    !!isFieldRequired && (
      <Box component="span" sx={{ color: "error.main" }}>
        **
      </Box>
    )
  );
});

const RenderField = ({ field, shouldRenderInput }) => {
  const isFieldEditable = useSelector((state) => selectIsFieldEditable(state, field.id));
  return shouldRenderInput && isFieldEditable ? <RenderInputDataField field={field} /> : <RenderRawDataField field={field} />;
};

const ITEMS_PER_PAGE = 6;
const PaginatedTaskFields = ({ updateTaskConfig, isEditModeOn, isConfigLoaded }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredFields = useMemo(() => {
    return updateTaskConfig.filter((field) => field.label.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [updateTaskConfig, searchQuery]);

  const totalPages = Math.ceil(filteredFields.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const visibleFields = filteredFields.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  return (
    <Fragment>
      <TextField
        fullWidth
        size="small"
        sx={{ p: 2, mb: 2, "& .MuiInputBase-root": { backgroundColor: "background.default" } }}
        variant="outlined"
        placeholder="Search fields..."
        id="outlined-search-field"
        type="search"
        value={searchQuery}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search color="action" />
            </InputAdornment>
          ),
        }}
      />
      <Grid container spacing={2} sx={{ p: 2 }}>
        {visibleFields.map((field) => (
          <Grid item xs={12} sm={12} md={12} {...(!isEditModeOn && { xl: 6 })} key={field.id}>
            <GridLabelValueUI
              valueSx={{
                "& .MuiSvgIcon-root, & .MuiIcon-root": { color: "primary.main" },
                "& .MuiFormControl-root": { m: 0, minWidth: "260px" },
                "& .MuiInputBase-root": { paddingRight: "14px" },
                fontSize: 14,
              }}
              boxContainerProps={{ flexDirection: "row" }}
              labelSx={{
                textWrap: "nowrap",
                fontSize: 14,
                fontWeight: 400,
              }}
              dividerSx={{ mb: 2 }}
              label={
                <div>
                  {field.label} {!!isEditModeOn && <RenderMandatoryLabelOnEdit fieldId={field.id} />}
                </div>
              }
            >
              <RenderField field={field} shouldRenderInput={!!isConfigLoaded && !!isEditModeOn} />
            </GridLabelValueUI>
          </Grid>
        ))}
      </Grid>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mt: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "background.paper",
          borderRadius: 1,
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <UiIconButton
            title="Previous Page"
            onClickIcon={handlePrevPage}
            disabled={currentPage === 1}
            sx={{
              "&.Mui-disabled": {
                backgroundColor: "action.disabledBackground",
              },
            }}
          >
            <NavigateBefore />
          </UiIconButton>

          <Typography variant="body1" sx={{ minWidth: 100, textAlign: "center" }}>
            Page {currentPage} of {totalPages}
          </Typography>

          <UiIconButton
            title="Next Page"
            onClickIcon={handleNextPage}
            disabled={currentPage === totalPages}
            sx={{
              "&.Mui-disabled": {
                backgroundColor: "action.disabledBackground",
              },
            }}
          >
            <NavigateNext />
          </UiIconButton>
        </Stack>
      </Paper>
    </Fragment>
  );
};

const DisplayAdditionalTaskFieldDetails = memo(({ additionalTaskFields }) => {
  const isEditModeOn = useSelector(selectIsAdditionalTaskFieldsEditModeOn);
  const isConfigLoaded = useSelector(selectIsConfigDataLoaded);
  const dispatch = useDispatch();

  const updateTaskConfig = useMemo(() => {
    return additionalTaskFields.map(
      ({ id = "unknown", data_type = "string", label = "unknown", key_value = "", isRequired = false, options = [], free_solo }) => {
        const isDateField = IS_DATE(data_type);
        const isMultiSelect = IS_MULTI_SELECT(data_type);

        const baseConfig = {
          id,
          isRequired,
          isEditable: true,
          value: key_value?.length ? key_value : isMultiSelect ? [] : "",
          label,
          fieldType: Object.values(SERVER_FIELD_TYPE).includes(data_type) ? data_type : SERVER_FIELD_TYPE.TEXT,
          alternateValue: "-",
          valueProviderProps: {},
          textFieldProps: {
            id,
            name: id,
            placeholder: `Enter value for ${String(label).toLocaleLowerCase()}...`,
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <Abc />
                </InputAdornment>
              ),
            },
          },
        };

        if (IS_SELECT(data_type) || isMultiSelect) {
          baseConfig.textFieldProps.noOptionsText = "No Options Available";
        }

        if (isMultiSelect) {
          baseConfig.textFieldProps.multiple = true;
          baseConfig.renderData = (_, dataValue) => (dataValue ?? []).join(" | ");
        }

        if (isDateField) {
          baseConfig.renderData = (_, dataValue) =>
            serverToUi(
              dataValue,
              data_type === SERVER_FIELD_TYPE.TIME
                ? DATE_TIME_FORMAT.UI_TIME_FORMAT
                : data_type === SERVER_FIELD_TYPE.DATE_TMS14
                  ? DATE_TIME_FORMAT.UI_FULL_FORMAT
                  : DATE_TIME_FORMAT.UI_DATE_FORMAT
            );

          baseConfig.componentProps = {
            id,
            slotProps: { inputAdornment: { position: "start" } },
            slots: { openPickerIcon: AccessTimeFilled },
          };
        }

        if (options.length) baseConfig.optionsData = options;
        if (free_solo) baseConfig.freeSolo = free_solo;

        return baseConfig;
      }
    );
  }, [additionalTaskFields]);

  useEffect(() => {
    if (!isConfigLoaded && isEditModeOn) {
      dispatch(setTasksFormConfig(updateTaskConfig.map(({ id, isRequired, isEditable, value }) => ({ id, isRequired, isEditable, value }))));
    }
  }, [dispatch, updateTaskConfig, isEditModeOn, isConfigLoaded, additionalTaskFields]);

  return <PaginatedTaskFields isConfigLoaded={isConfigLoaded} isEditModeOn={isEditModeOn} updateTaskConfig={updateTaskConfig} />;
});

export default function DynamicAdditionalTaskField({ dynamicTaskFieldData, refetchData }) {
  const dispatch = useDispatch();
  /* INFO: Redux Memory Cleanup on unmount component */
  useEffect(() => {
    return () => dispatch(clearAllTaskFormFields());
  }, [dispatch]);

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          py: 1,
          mt: 1,
          position: "sticky",
          top: 0,
          zIndex: 100,
          backgroundColor: "background.default",
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <ReadMore color="primary" sx={{ m: 1 }} />
          <Typography variant="h6">Other Task Details</Typography>
        </Stack>
        <EditChangesButtonGroup refetchData={refetchData} />
      </Stack>
      <Box sx={{ ...sectionUiLookBase, p: 2, my: 1 }}>
        <DisplayAdditionalTaskFieldDetails additionalTaskFields={dynamicTaskFieldData} />
      </Box>
    </Box>
  );
}
