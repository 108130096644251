import { EditNote, HowToReg, Timelapse } from "@mui/icons-material";
import { darken, lighten } from "@mui/system";
import { format } from "date-fns";
import { Fragment } from "react";
import { normalizeConfigForTable, serverDate, serverToUi } from "../../../app/utils/utilityFunctions";
import no_content_img from "../../../asset/images/no_content.png";
import { generalTableConfig } from "../../../components/config/generalTableConfig";
import { StyledChip } from "../../../styled/tableStyles";
import ResolveAttendanceDialog from "../ResolveAttendanceDialog";
import ResolveOvertimeDialog from "../ResolveOvertimeDialog";
import { conflictedAttendanceColumnView, overtimeAttendanceColumnView, routineAttendanceColumnView } from "./attendanceColumns";
import { ATTENDANCE_ADJUSTMENT, ATTENDANCE_OVERTIME, ROUTINE_ATTENDANCE } from "./attendanceUtils";

const createGroupConfig =
  (groupByValue = (v) => v) =>
  (rows) => {
    const transform = (data) => (data ? format(serverDate(data), "EEEE - MMMM d, yyyy") : "");
    const uniqueValues = [...new Set(rows.map(groupByValue))].sort((a, b) => serverDate(b) - serverDate(a));

    return {
      baseRowStyles: {
        borderLeft: "2px solid",
        borderRight: "2px solid",
        borderColor: "primary.main",
        backgroundColor: (theme) =>
          theme.palette.mode === "dark" ? darken(theme.palette.secondary.light, 0.675) : lighten(theme.palette.primary.light, 0.85),
        "& .MuiTypography-root": {
          color: "primary.main",
          fontWeight: 500,
          textAlign: "center",
          fontSize: "1.1rem",
        },
      },
      groups: uniqueValues.map((value) => ({
        id: `group_config-${value}`.toLowerCase(),
        groupText: transform(value),
        filterData: (rowData) => rowData.filter((row) => groupByValue(row) === value),
        noRecordsFoundUi: {
          src: no_content_img,
          altSrc: "No Data",
          title: `No Records for ${transform(value)}`,
          subTitle: "Try adjusting your filters",
        },
      })),
    };
  };

const attendanceTableConfig = (tableId, exportTableId, tableTitle, columnView, valueConfig = null, tableDialogComponent = null) =>
  normalizeConfigForTable(
    generalTableConfig({
      tableId,
      exportTableId,
      tableTitle,
      tableViewColumns: columnView,
      preventDisplayOf: {
        selectSortDataByDisplay: false,
        selectDateRangeDisplay: false,
        filterOptionsDisplay: false,
        addRowButtonDisplay: false,
      },
      otherDefaultsVariables: {
        paginationTitle: "Employees / Page",
      },
      paginationRowsOptions: {
        pageOptions: [10, 20, 30],
      },
      ...(valueConfig && {
        groupRows: createGroupConfig(valueConfig),
      }),
      ...(tableDialogComponent && {
        tableRowClickNavigate: {
          tableOpenDialogForRow: true,
          tableDialogComponent,
        },
      }),
    })
  );

const TableTypography = ({ title, activeDate, defaultDate }) => (
  <Fragment>
    {title}
    <StyledChip
      label={activeDate ? serverToUi(activeDate) : defaultDate ? serverToUi(defaultDate) : "No Date Selected"}
      color="primary"
      sx={{ borderRadius: 1, ml: 1 }}
    />
  </Fragment>
);

export const attendanceTabData = {
  [ATTENDANCE_ADJUSTMENT]: {
    id: ATTENDANCE_ADJUSTMENT,
    label: "Attendance Adjustment",
    color: "error",
    getConfig: (activeDate) =>
      attendanceTableConfig(
        ATTENDANCE_ADJUSTMENT,
        "att_adj",
        <TableTypography title="Attendance Adjustment Data" activeDate={activeDate} />,
        conflictedAttendanceColumnView,
        !activeDate ? (row) => row?.["actual"]?.["bus_dt"] : null,
        (props) => <ResolveAttendanceDialog {...props} />
      ),
    get icon() {
      return <EditNote color={this.color} />;
    },
  },
  [ROUTINE_ATTENDANCE]: {
    id: ROUTINE_ATTENDANCE,
    label: "Attendance",
    color: "success",
    getConfig: (activeDate) =>
      attendanceTableConfig(
        ROUTINE_ATTENDANCE,
        "att_rt",
        <TableTypography title="Attendance Data" activeDate={activeDate} defaultDate={new Date()} />,
        routineAttendanceColumnView
      ),
    get icon() {
      return <HowToReg color={this.color} />;
    },
  },
  [ATTENDANCE_OVERTIME]: {
    id: ATTENDANCE_OVERTIME,
    label: "Overtime",
    color: "warning",
    getConfig: (activeDate) =>
      attendanceTableConfig(
        ATTENDANCE_OVERTIME,
        "att_ot",
        <TableTypography title="Overtime Data" activeDate={activeDate} />,
        overtimeAttendanceColumnView,
        !activeDate ? (row) => row?.["bus_dt"] : null,
        (props) => <ResolveOvertimeDialog {...props} />
      ),
    get icon() {
      return <Timelapse color={this.color} />;
    },
  },
};
