import { ChevronLeft } from "@mui/icons-material";
import { Avatar, Box, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip, Typography } from "@mui/material";
import { Fragment, memo, useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { matchRoute } from "../../app/utils/utilityFunctions";
import logo from "../../asset/images/logo.png";
import { MuiTooltip } from "../../styled/commonStyles";
import { DrawerHeader, MuiDrawer, sideBarListItemButtonStyles } from "../../styled/layoutStyles";

const getNavigationPath = (path) => path.replace(/\/:\w+\?/g, "").replace(/\/:\w+/g, "");

const NavItem = memo(({ open, item, isActive }) => {
  const navigationPath = useMemo(() => getNavigationPath(item.path), [item.path]);
  return (
    <MuiTooltip title={open ? null : item.tooltip || item.pageTitle} arrow placement="right">
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{ ...sideBarListItemButtonStyles(open, isActive), justifyContent: open ? "initial" : "center" }}
          LinkComponent={Link}
          to={navigationPath}
          onClick={(e) => e.stopPropagation()}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 1.5 : "auto",
              justifyContent: "center",
            }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.label} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    </MuiTooltip>
  );
});

const NavGroup = memo(({ open, navGroup, isActiveItem }) => (
  <Fragment>
    <ListItem disablePadding sx={{ display: open ? "block" : "none", mt: 2 }}>
      <ListItemButton disableTouchRipple disableRipple disabled sx={{ opacity: "1 !important" }}>
        <ListItemText
          primary={navGroup.label}
          sx={{
            color: "#FFF",
            "& .MuiTypography-root": {
              textTransform: "uppercase",
              fontWeight: 600,
              letterSpacing: "1px",
              fontSize: "0.875rem",
              color: "rgba(255, 255, 255, 0.6)",
            },
          }}
        />
      </ListItemButton>
    </ListItem>
    <List component="div" disablePadding>
      {navGroup.items?.map((item) => (
        <Fragment key={item.id}>
          {item.type === "item" ? (
            <NavItem item={item} open={open} isActive={isActiveItem(item)} />
          ) : (
            <NavGroup navGroup={item} open={open} isActiveItem={isActiveItem} />
          )}
        </Fragment>
      ))}
    </List>
  </Fragment>
));

export default function Sidebar({ open, handleDrawerClose, navItems }) {
  const { pathname } = useLocation();

  const isActiveItem = useCallback(
    (item) => {
      if (matchRoute(pathname, item.path).isMatch) return true;
      if (item.childRoutes?.some((route) => matchRoute(pathname, route).isMatch)) return true;
      return false;
    },
    [pathname]
  );

  return (
    <MuiDrawer
      onClick={handleDrawerClose}
      variant="permanent"
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "background.drawerBg",
          borderRight: 0,
          top: "var(--app-bar-height)",
        },
      }}
      sx={{
        "& .MuiIconButton-root": {
          color: "#FFF",
          ":hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
          },
        },
        "& .MuiListItemButton-root": {
          borderRadius: "6px",
        },
      }}
    >
      <DrawerHeader>
        <Box gap={1.5} sx={{ flex: 1, display: open ? "flex" : "none", alignItems: "center" }}>
          <Avatar src={logo} sx={{ height: "42px", width: "42px" }}>
            NIKGIL
          </Avatar>
          <Typography sx={{ letterSpacing: "2px", fontWeight: 600, fontSize: "1.125rem", flex: 1, color: "#FFF", fontStyle: "italic" }}>
            NIKGIL
          </Typography>
        </Box>
        <Tooltip title="Collapse" arrow>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft sx={{ height: "28px", width: "28px" }} />
          </IconButton>
        </Tooltip>
      </DrawerHeader>
      <Divider sx={{ borderColor: "rgba(255, 255, 255, 0.12)" }} />
      <List sx={{ my: open ? 2 : "auto" }}>
        {navItems?.map((item) => (
          <Fragment key={item.id}>
            {item.type === "item" ? (
              <NavItem item={item} open={open} isActive={isActiveItem(item)} />
            ) : (
              <NavGroup navGroup={item} open={open} isActiveItem={isActiveItem} />
            )}
          </Fragment>
        ))}
      </List>
    </MuiDrawer>
  );
}
