import { Box, Switch, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export const getApiUrl = () => {
  // eslint-disable-next-line no-undef
  if (process.env.REACT_APP_ENV !== "LOCAL") {
    // eslint-disable-next-line no-undef
    return process.env.REACT_APP_BE_URL;
  }

  const isProd = JSON.parse(localStorage.getItem("isProdLink") || "false");
  // eslint-disable-next-line no-undef
  return isProd ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;
};

export const DebugComponent = () => {
  const isDebugModeOn = process.env.REACT_APP_ENV === "LOCAL";
  const [isProdLink, setIsProdLink] = useState(false);

  // Load last selected state from localStorage
  useEffect(() => {
    const savedState = localStorage.getItem("isProdLink");
    if (savedState) {
      setIsProdLink(JSON.parse(savedState));
    }
  }, []);

  const handleChange = (event) => {
    const newState = event.target.checked;
    setIsProdLink(newState);
    localStorage.setItem("isProdLink", JSON.stringify(newState));
    window.location.reload();
  };

  if (!isDebugModeOn) return null;

  return (
    <Box
      sx={{
        backgroundColor: isProdLink ? "error.background" : "info.background",
        padding: "4px 12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        fontSize: "14px",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 99999,
      }}
    >
      <Typography variant="body1" component="span">
        Mode -
        <Typography variant="body1" component="span" sx={{ color: isProdLink ? "error.main" : "info.main", fontWeight: "bold" }}>
          {isProdLink ? " PRODUCTION" : " DEVELOPMENT"}
        </Typography>
      </Typography>
      <Switch
        checked={isProdLink}
        onChange={handleChange}
        inputProps={{ "aria-label": "API environment switch" }}
        size="small"
        color={isProdLink ? "error" : "success"}
      />
    </Box>
  );
};
