import { Clear, DoneAll, Launch } from "@mui/icons-material";
import { Box, Link } from "@mui/material";
import { formatDuration, intervalToDuration } from "date-fns";
import { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { DATE_TIME_FORMAT } from "../../../app/constants/common";
import { SERVER_FIELD_TYPE } from "../../../app/constants/config/DynamicFields";
import { routeToSingleTask } from "../../../app/constants/routes";
import { serverToUi } from "../../../app/utils/utilityFunctions";
import { RenderOnDemandField } from "../../../components/ui/InputUI";

export const OVERTIME_RESOLVE_STATUS = {
  APPROVE: "approve",
  REJECT: "reject",
};

export const DIALOG_INITIAL_STATE = {
  row: null,
  open: false,
  refreshData: true,
};

export const OVERTIME_ACTIONS = [
  {
    id: OVERTIME_RESOLVE_STATUS.APPROVE,
    label: "Approve",
    color: "success",
    icon: DoneAll,
  },
  {
    id: OVERTIME_RESOLVE_STATUS.REJECT,
    label: "Reject",
    color: "error",
    icon: Clear,
  },
];

export const DIALOG_FIELDS = [
  {
    id: "employeeName",
    label: "Employee Name",
    renderData: (data) => data || "-",
  },
  {
    id: "task_id",
    label: "Employee Task",
    renderData: (data) =>
      data ? (
        <Link
          color="text.primary"
          component={RouterLink}
          to={routeToSingleTask(data)}
          underline="hover"
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
          target="_blank"
          rel="noopener"
        >
          <Launch sx={{ color: "primary.main" }} />
          <span>{data}</span>
        </Link>
      ) : (
        "-"
      ),
  },
  {
    id: "remark",
    label: "Employee Remark",
    renderData: (data) => data || "-",
  },
  {
    id: "bus_dt",
    label: "Overtime Date",
    renderData: (data) => serverToUi(data) || "-",
  },
  {
    id: "start_time",
    label: "Overtime Start",
    editable: true,
    renderData: (data, { onChange } = {}, isEditing) =>
      !isEditing ? (
        serverToUi(data, DATE_TIME_FORMAT.UI_TIME_FORMAT)
      ) : (
        <RenderOnDemandField fieldType={SERVER_FIELD_TYPE.TIME} fieldValue={data} onChange={onChange || (() => null)} size="small" />
      ),
  },
  {
    id: "end_time",
    label: "Overtime End",
    editable: true,
    renderData: (data, { onChange } = {}, isEditing) =>
      !isEditing ? (
        serverToUi(data, DATE_TIME_FORMAT.UI_TIME_FORMAT)
      ) : (
        <RenderOnDemandField fieldType={SERVER_FIELD_TYPE.TIME} fieldValue={data} onChange={onChange || (() => null)} size="small" />
      ),
  },
  {
    id: "overtime_duration",
    label: "Overtime Duration",
    renderData: (totalDuration, { row, resolvedData } = {}) => {
      if (!totalDuration) return "-";

      const duration = formatDuration(
        intervalToDuration({
          start: 0,
          end: totalDuration * 60 * 1000,
        }),
        { format: ["hours", "minutes"] }
      );

      const isTimeChanged = row?.start_time !== resolvedData.start_time || row?.end_time !== resolvedData.end_time;

      return (
        <Fragment>
          {duration}
          {!!isTimeChanged && (
            <Box component="em" sx={{ color: "warning.main", ml: 1 }}>
              (proposed)
            </Box>
          )}
        </Fragment>
      );
    },
  },
  {
    id: "appr_name",
    label: "Reviewer Name",
    renderData: (data) => data || "N/A",
  },
  {
    id: "appr_remark",
    label: "Reviewer Remark",
    editable: true,
    renderData: (data, { onChange } = {}, isEditing) =>
      !isEditing ? (
        data || "-"
      ) : (
        <RenderOnDemandField field={{ textFieldProps: { multiline: true, maxRows: 4 } }} fieldValue={data || ""} onChange={onChange} size="small" />
      ),
  },
];

export const ALERT_MESSAGES = {
  success: {
    title: "Overtime Resolved Successfully",
    message: "You have successfully resolved overtime for employee",
  },
  error: {
    title: "Overtime Resolution Failed",
  },
};
