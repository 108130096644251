import { darken, lighten } from "@mui/system";

const popperPropsForFullScreen = { container: () => document.fullscreenElement ?? document.body };

export const designTheme = (mode) => ({
  components: {
    MuiFormHelperText: { defaultProps: { sx: { fontWeight: 600 } } },
    MuiInputLabel: { defaultProps: { sx: { fontWeight: 600 } } },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            fontStyle: "italic",
            fontSize: "0.8125rem",
            fontWeight: 400,
            opacity: 1,
            color: mode === "light" ? "#606060" : "#bfbfbf",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize !important",
          fontWeight: "600 !important",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "capitalize !important",
          fontWeight: "600 !important",
        },
      },
    },
    MuiPaper: {
      styleOverrides: { root: { backgroundImage: "none" } },
    },
    MuiTooltip: {
      defaultProps: {
        PopperProps: popperPropsForFullScreen,
      },
    },
    MuiSelect: {
      defaultProps: {
        MenuProps: popperPropsForFullScreen,
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        slotProps: {
          popper: popperPropsForFullScreen,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        html: {
          "*::-webkit-scrollbar": {
            width: "14px",
            height: "14px",
            backgroundColor: themeParam.palette.mode === "dark" ? "#303030" : "#F5F5F5",
          },
          "*::-webkit-scrollbar-corner": {
            backgroundColor: "transparent",
          },
          "*::-webkit-scrollbar-track": {
            backgroundColor: themeParam.palette.mode === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
            borderRadius: "14px",
          },
          "*::-webkit-scrollbar-thumb": {
            backgroundColor: themeParam.palette.mode === "dark" ? "rgba(255, 255, 255, 0.4)" : "rgba(0, 0, 0, 0.4)",
            borderRadius: "14px",
            border: "2px solid transparent",
            backgroundClip: "content-box",
          },
          "*::-webkit-scrollbar-thumb:hover": {
            backgroundColor: themeParam.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "rgba(0, 0, 0, 0.7)",
          },
          "*::-webkit-scrollbar-thumb:active": {
            backgroundColor: themeParam.palette.mode === "dark" ? "rgba(255, 255, 255, 0.9)" : "rgba(0, 0, 0, 0.9)",
          },
          ":root": {
            "--app-bar-height": process.env.REACT_APP_ENV === "LOCAL" ? "32px" : "0px",
          },
        },
        ".tiptap.ProseMirror > .is-empty.is-editor-empty::before": {
          fontStyle: "italic",
          fontSize: "0.8725rem",
          lineHeight: "1.5",
          fontWeight: 400,
          opacity: 1,
          color: `${themeParam.palette.text.secondary} !important`,
        },
        ".MuiTiptap-RichTextContent-root > .tiptap.ProseMirror": {
          wordBreak: "break-word",
          overflowWrap: "break-word",
          hyphens: "auto",
        },
        "input[type=checkbox]": {
          accentColor: themeParam.palette.primary.main,
        },
      }),
    },
    MuiIcon: {
      defaultProps: {
        baseClassName: "material-symbols-outlined",
      },
    },
  },
  typography: {
    fontSize: 13,
    fontFamily: ["'Poppins'", "sans-serif"].join(","),
  },
  palette: {
    mode,
    primary: {
      ...(mode === "light"
        ? {
            main: "#016604",
            sectionContainer: "#FFF",
            sectionBorder100: "rgba(24, 24, 24, 0.12)",
            sectionBorder600: "rgba(0, 0, 0, 0.17)",
            background: lighten("#016604", 0.86),
          }
        : {
            main: "#21C063",
            sectionContainer: "#0B141B",
            sectionBorder100: "rgba(130, 130, 130, 0.12)",
            sectionBorder600: "rgba(255, 255, 255, 0.12)",
            background: "#103629",
          }),
    },
    background: {
      ...(mode === "light"
        ? {
            default: "#F3F6FD",
            drawerBg: "#00180a",
            paper: "#f8faff",
          }
        : {
            default: "#060F16",
            drawerBg: "#07141f",
            paper: "#0B141B",
          }),
    },
    text: {
      ...(mode === "light"
        ? {
            primary: "#000",
            secondary: "#606060",
            reverse: "#e9e9e9",
          }
        : {
            primary: "#e9e9e9",
            secondary: "#bfbfbf",
            reverse: "#000",
          }),
    },
    shape: {
      boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    },
    secondary: {
      ...(mode === "dark"
        ? { main: "#A367B1", background: darken("#A367B1", 0.78) }
        : {
            main: "#9c27b0",
            background: lighten("#9c27b0", 0.86),
          }),
    },
    warning: {
      ...(mode === "dark"
        ? { main: "#ff9800", background: darken("#ff9800", 0.77) }
        : {
            main: "#EE7214",
            background: lighten("#EE7214", 0.85),
          }),
    },
    error: {
      main: "#d32f2f",
      ...(mode === "dark"
        ? {
            main: "#be123c",
            background: darken("#f43f5e", 0.84),
          }
        : {
            main: "#c51d1c",
            background: lighten("#c51d1c", 0.86),
          }),
    },
    success: {
      ...(mode === "dark"
        ? {
            main: "#34d399",
            background: darken("#10b981", 0.78),
          }
        : {
            main: "#2e7d32",
            background: lighten("#2e7d32", 0.88),
          }),
    },
    info: {
      ...(mode === "dark"
        ? {
            main: "#0288d1",
            background: darken("#0288d1", 0.74),
          }
        : {
            main: "#1947d7",
            background: lighten("#1947d7", 0.88),
          }),
    },
  },
});
