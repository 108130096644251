import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { createElement, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getComparator, stableSort } from "../../app/utils/utilityFunctions";
import no_content_img from "../../asset/images/no_content.png";
import { StyledTableCell, StyledTableRow } from "../../styled/tableStyles";
import {
  isRowIdSelected,
  selectActiveColumns,
  selectActiveRows,
  selectCustomTableCellData,
  selectDataInOrder,
  selectOriginalRowCount,
  selectPage,
  selectPrimaryColumnId,
  selectRowsPerPage,
  selectSearchString,
  selectSelectedRowsCount,
  setCustomData,
  setSelectedRows,
} from "../slices/advancedTable";
import { EmptyDataVisualComp } from "../ui/UiUtils";

const TableCellComponent = memo(({ isTrueColumnField, whiteSpace, align, disablePadding, primaryKey, extraPadding, children, ...otherProps }) => (
  <StyledTableCell
    sx={{
      ...(extraPadding && { paddingRight: isTrueColumnField && align === "center" ? "36px" : "16px" }),
      ...(whiteSpace && { whiteSpace }),
    }}
    {...(disablePadding && { padding: "none" })}
    {...(primaryKey
      ? {
          component: "th",
          scope: "row",
          padding: "none",
        }
      : { align })}
    {...otherProps}
  >
    {children}
  </StyledTableCell>
));

const TableRowCellWrapper = ({ renderContent, fnParams: { params, extraParams }, rowId, columnId, ...tableCellProps }) => {
  const state = useSelector((state) => selectCustomTableCellData(state, columnId, rowId));
  const dispatch = useDispatch();
  const onChange = useCallback((data) => dispatch(setCustomData({ columnId, rowId, data })), [dispatch, rowId, columnId]);
  return <TableCellComponent {...tableCellProps}>{renderContent(...params, state, onChange, ...extraParams)}</TableCellComponent>;
};

const TableHeaderCheckBox = ({ areRowsSelectable }) => {
  const selectedRowsLength = useSelector(selectSelectedRowsCount);
  const rowCount = useSelector(selectOriginalRowCount);
  const dispatch = useDispatch();
  const handleSelectAllClick = (event) => dispatch(setSelectedRows({ isChecked: event.target.checked }));

  return (
    areRowsSelectable && (
      <TableCellComponent padding="checkbox">
        <Checkbox
          color="primary"
          indeterminate={selectedRowsLength > 0 && selectedRowsLength < rowCount}
          checked={rowCount > 0 && selectedRowsLength === rowCount}
          onChange={handleSelectAllClick}
          inputProps={{
            "aria-label": "Select Row",
          }}
        />
      </TableCellComponent>
    )
  );
};

const TableRowCheckbox = ({ rowId, areRowsSelectable }) => {
  const isItemSelected = useSelector(isRowIdSelected(rowId));
  const dispatch = useDispatch();

  const handleSelectClick = useCallback(
    (event) => {
      event.stopPropagation();
      dispatch(setSelectedRows({ isChecked: event.target.checked, id: rowId }));
    },
    [rowId, dispatch]
  );

  return (
    areRowsSelectable && (
      <TableCellComponent role="checkbox" aria-checked={isItemSelected} padding="checkbox" onClick={handleSelectClick}>
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-label": "Select All Rows",
          }}
        />
      </TableCellComponent>
    )
  );
};

const TableRowComponent = ({ clickableProps, row, onClickRow, areRowsSelectable, columnProps, refetchData }) => {
  const activeColumns = useSelector(selectActiveColumns);
  const searchTerm = useSelector(selectSearchString);
  const theme = useTheme();

  return (
    <StyledTableRow hover tabIndex={-1} key={row.id}>
      <TableRowCheckbox areRowsSelectable={areRowsSelectable} rowId={row.id} />
      {activeColumns.map((column, index) => {
        const cellProps = columnProps[column.id];
        if (!cellProps) return null;
        const tableCellProps = {
          key: index,
          align: cellProps.align,
          disablePadding: cellProps.disablePadding,
          primaryKey: cellProps.primaryKey,
          extraPadding: true,
          whiteSpace: cellProps.whiteSpace,
          isTrueColumnField: cellProps.isTrueColumnField,
          component: clickableProps.isClickable ? (clickableProps.isNavigable ? Link : "th") : "th",
          ...(clickableProps.isClickable ? (clickableProps.isNavigable ? { to: onClickRow(row)() } : { onClick: onClickRow(row) }) : {}),
          ...(clickableProps.isClickable && { style: { cursor: "pointer" } }),
        };

        const renderFunction = {
          renderContent:
            cellProps.isTrueColumnField && (row[column.id] === null || row[column.id] === undefined)
              ? () => cellProps.alternateValue
              : cellProps.renderContent,
          fnParams: {
            params: cellProps.isTrueColumnField ? [row[column.id], String(searchTerm).trim().toLowerCase()] : [row, refetchData],
            extraParams: [theme, ...(cellProps.isTrueColumnField ? [row] : [])].filter(Boolean),
          },
        };

        return cellProps.storeCustomDataOnRender ? (
          <TableRowCellWrapper {...tableCellProps} {...renderFunction} rowId={row.id} columnId={column.id} />
        ) : (
          <TableCellComponent {...tableCellProps}>
            {renderFunction.renderContent(...renderFunction.fnParams.params, ...renderFunction.fnParams.extraParams)}
          </TableCellComponent>
        );
      })}
    </StyledTableRow>
  );
};

const DataTableHeader = memo(({ order, orderBy, onRequestSort, areRowsSelectable, columnProps }) => {
  const createSortHandler = (property) => () => onRequestSort(property);
  const activeColumns = useSelector(selectActiveColumns);

  return (
    <TableHead>
      <StyledTableRow>
        <TableHeaderCheckBox areRowsSelectable={areRowsSelectable} />
        {activeColumns.map(({ id }) => {
          const column = columnProps[id];
          if (!column) return null;
          return (
            <TableCellComponent
              key={column.id}
              align={column.align}
              padding={column.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === column.id ? order : false}
            >
              {column.isTrueColumnField ? (
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : "asc"}
                  onClick={createSortHandler(column.id)}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc" ? "sorted descending" : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                column.label
              )}
            </TableCellComponent>
          );
        })}
      </StyledTableRow>
    </TableHead>
  );
});

const DataTableBody = ({ clickableProps, order, orderBy, areRowsSelectable, columnProps, onClickRow, groupRows, refetchData }) => {
  const currentRows = useSelector(selectActiveRows);
  const page = useSelector(selectPage);
  const activeColumns = useSelector(selectActiveColumns);
  const rowsPerPage = useSelector(selectRowsPerPage) || currentRows.length;
  const groupConfig = typeof groupRows === "function" ? groupRows(currentRows) : groupRows;

  const visibleRows = useMemo(() => {
    const createTitleRow = (group) => ({
      isNotActualRow: true,
      colSpan: activeColumns.length + 1,
      renderGroupContent: <Typography>{group.groupText}</Typography>,
      rowStyles: groupConfig.baseRowStyles,
    });

    const createEmptyDataVisualRow = (group) => ({
      isNotActualRow: true,
      colSpan: activeColumns.length + 1,
      renderGroupContent: <EmptyDataVisualComp {...group.noRecordsFoundUi} />,
      rowStyles: {},
    });

    if (!groupConfig?.groups) {
      const sortedRows = stableSort(currentRows, getComparator(order, orderBy));
      const startIndex = page * rowsPerPage;
      return sortedRows.slice(startIndex, startIndex + rowsPerPage);
    }

    const sortedRows = stableSort(currentRows, getComparator(order, orderBy));

    // Calculate which rows should be visible
    let result = [];
    let processedRows = 0;
    let targetStartRow = page * rowsPerPage;
    let rowsToShow = rowsPerPage;

    // Process each group
    for (const group of groupConfig.groups) {
      const groupData = group.filterData(sortedRows);
      if (groupData.length === 0) {
        result.push(createTitleRow(group));
        result.push(createEmptyDataVisualRow(group));
        continue;
      }

      if (processedRows + groupData.length <= targetStartRow) {
        processedRows += groupData.length;
        continue;
      }

      // Add group header if this group will show any rows
      result.push(createTitleRow(group));

      // Calculate slice indexes for this group
      const groupStartIndex = Math.max(0, targetStartRow - processedRows);
      const groupEndIndex = Math.min(groupData.length, groupStartIndex + rowsToShow);

      // Add rows from this group
      result.push(...groupData.slice(groupStartIndex, groupEndIndex));

      // Update counters
      rowsToShow -= groupEndIndex - groupStartIndex;
      processedRows += groupData.length;

      // If we've added all rows we need, break
      if (rowsToShow <= 0) break;
    }

    return result;
  }, [rowsPerPage, activeColumns, order, orderBy, currentRows, groupConfig, page]);

  return visibleRows.length > 0 ? (
    visibleRows.map((row, index) =>
      row.isNotActualRow ? (
        <StyledTableRow tabIndex={-1} key={index} sx={{ pointerEvents: "none", ...row.rowStyles }}>
          <TableCellComponent colSpan={row.colSpan}>{row.renderGroupContent}</TableCellComponent>
        </StyledTableRow>
      ) : (
        <TableRowComponent
          key={index}
          clickableProps={clickableProps}
          row={row}
          areRowsSelectable={areRowsSelectable}
          columnProps={columnProps}
          onClickRow={onClickRow}
          refetchData={refetchData}
        />
      )
    )
  ) : (
    <StyledTableRow
      sx={{
        pointerEvents: "none",
        backgroundColor: "transparent !important",
        border: "0 !important",
        borderTop: "1px solid !important",
        borderColor: (theme) => `${theme.palette.primary.sectionBorder600} !important`,
      }}
    >
      <TableCellComponent colSpan={activeColumns.length + 1}>
        <EmptyDataVisualComp
          src={no_content_img}
          altSrc="No Data Found"
          title="No Results Found"
          subTitle={"Couldn't find data matching your criteria. Data may be unavailable or try revising your filters/search"}
        />
      </TableCellComponent>
    </StyledTableRow>
  );
};

export default function DataTable({
  config: {
    columnProps,
    areRowsSelectable,
    onClickProps: { navigateToURL, url, navigateUsingRowAttribute, openDialogForRow, dialogComponent },
    groupRows,
    defaultSortColumnId,
  },
  refetchData,
}) {
  const clickableProps = { isClickable: navigateToURL || openDialogForRow, isNavigable: !!navigateToURL };
  const sortDataInOrder = useSelector(selectDataInOrder);
  const primaryColumnId = useSelector(selectPrimaryColumnId);
  const [order, setOrder] = useState(sortDataInOrder);
  const [orderBy, setOrderBy] = useState(defaultSortColumnId || primaryColumnId);
  const [dialogProps, setDialogProps] = useState({ refreshData: false, row: null, open: false });

  const onClickRow = useCallback(
    (row) => () => (navigateToURL ? url(row[navigateUsingRowAttribute]) : openDialogForRow ? setDialogProps(() => ({ row, open: true })) : null),
    [navigateToURL, navigateUsingRowAttribute, openDialogForRow, url]
  );

  useEffect(() => {
    setOrder(sortDataInOrder.value);
  }, [sortDataInOrder.value]);

  useEffect(() => {
    if (dialogProps.refreshData) refetchData();
  }, [refetchData, dialogProps.refreshData]);

  const handleRequestSort = useCallback(
    (property) => {
      setOrder(orderBy === property && order === "Ascending_Order" ? "Descending_Order" : "Ascending_Order");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  return (
    <Table
      aria-labelledby="tableTitle"
      sx={{
        // TODO: For Responsiveness: transform: "rotateX(180deg)",
        minWidth: 450,
      }}
    >
      <DataTableHeader
        order={order === "Ascending_Order" ? "asc" : "desc"}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        areRowsSelectable={areRowsSelectable}
        columnProps={columnProps}
      />
      <TableBody>
        <DataTableBody
          clickableProps={clickableProps}
          areRowsSelectable={areRowsSelectable}
          columnProps={columnProps}
          groupRows={groupRows}
          onClickRow={onClickRow}
          order={order}
          orderBy={orderBy}
          refetchData={refetchData}
        />
      </TableBody>
      {!!openDialogForRow && !!dialogComponent && !!dialogProps.open && createElement(dialogComponent, { dialogProps, setDialogProps })}
    </Table>
  );
}
