import { AddTaskOutlined, Autorenew, ClearOutlined, ModeEditOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import { memo, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ERROR, SUCCESS, WARNING } from "../../../app/constants/common";
import { CONFIG_CONSTANTS_OPTIONS } from "../../../app/constants/config/AppSettings";
import { TASK_FIELDS } from "../../../app/constants/config/systemTasks";
import { routeTaskParam } from "../../../app/constants/routes";
import { RenderOnDemandField } from "../../../components/ui/InputUI";
import { BlockerComponent, GridLabelValueUI, UiIconButton } from "../../../components/ui/UiUtils";
import { useUpdateExistingTaskMutation } from "../../../hooks/ApiHooks";
import { useOptionsForTaskForm } from "../../../hooks/valueProviderHooks";
import { sectionUiLookBase } from "../../../styled/inlineCssStyles";
import { setAlert } from "../../Alerts/slice/alertSlice";
import { selectPresetConfig } from "../../AppSettings/appSlice";
import {
  clearAllTaskFormFields,
  selectAllTasksFieldsWithId,
  selectAreRequiredTaskFieldsFilled,
  selectIsConfigDataLoaded,
  selectIsFieldEditable,
  selectIsFieldRequired,
  selectIsTasksEditModeOn,
  setTasksEditMode,
  setTasksFormConfig,
} from "../slice/taskSlice";
import { updateOrRenderFormConfig } from "./config/updateTaskConfig";

const EditChangesButtonGroup = memo(({ refetchData }) => {
  const { [routeTaskParam]: taskId } = useParams();
  const isEditModeOn = useSelector(selectIsTasksEditModeOn);
  const areRequiredFieldsFilled = useSelector(selectAreRequiredTaskFieldsFilled);
  const updatedTaskData = useSelector(selectAllTasksFieldsWithId);
  const dispatch = useDispatch();
  const onChangeEdit = () => dispatch(setTasksEditMode());

  const { triggerMutation, isLoading, isError, error, data } = useUpdateExistingTaskMutation();

  useEffect(() => {
    if (isError) {
      const serviceUnavailable = error.status === 503;
      dispatch(setAlert(serviceUnavailable ? error.data.title : "Task Update Failed", error.data.message, serviceUnavailable ? ERROR : WARNING));
    }
  }, [dispatch, isError, error]);

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(clearAllTaskFormFields());
      dispatch(setAlert(`Task was updated successfully`, "Please wait while we fetch the updated data", SUCCESS));
      refetchData();
    }
  }, [dispatch, refetchData, isLoading, data]);

  const onSaveChanges = () => {
    triggerMutation(taskId, updatedTaskData);
  };

  return (
    <Stack gap={1} direction="row" alignItems="center" sx={{ "& .MuiButton-root": { fontWeight: 600 } }}>
      <Button
        variant="outlined"
        color={isEditModeOn ? "error" : "primary"}
        startIcon={isEditModeOn ? <ClearOutlined /> : <ModeEditOutlined />}
        onClick={onChangeEdit}
      >
        {isEditModeOn ? "Cancel" : "Edit"}
      </Button>
      <LoadingButton
        loading={isLoading}
        variant="contained"
        disabled={!isEditModeOn || !areRequiredFieldsFilled}
        startIcon={<AddTaskOutlined />}
        onClick={onSaveChanges}
        sx={{ mr: -0.5 }}
      >
        Save Changes
      </LoadingButton>
      <UiIconButton title="Refetch Data" onClickIcon={refetchData} disabled={isLoading}>
        <Autorenew />
      </UiIconButton>
      <BlockerComponent when={isLoading || isEditModeOn} />
    </Stack>
  );
});

const RenderRawDataField = memo(({ field, dataValue }) => {
  const theme = useTheme();
  const options = useSelector((state) => selectPresetConfig(state, field.valueProviderProps.fetchConfigId));
  return dataValue?.length ? field.renderData?.(options, dataValue, theme) || dataValue : field.alternateValue;
});

const RenderInputDataField = memo(({ field }) => {
  const { currentFieldValue, optionsData, isFieldDisabled, onChange } = useOptionsForTaskForm(
    field.id,
    field.fieldType,
    field.valueProviderProps,
    field.getOptionsByCurrentValue,
    field.excludedValueOptions
  );
  return (
    <RenderOnDemandField
      field={field}
      fieldType={field.fieldType}
      fieldValue={currentFieldValue}
      onChange={onChange}
      isFieldDisabled={isFieldDisabled}
      optionsData={optionsData}
      disableClearable={field.isRequired}
      getFromValue={field.getFromValue}
      setToValue={field.setToValue}
    />
  );
});

const RenderMandatoryLabelOnEdit = memo(({ fieldId }) => {
  const isFieldRequired = useSelector((state) => selectIsFieldRequired(state, fieldId));
  return (
    !!isFieldRequired && (
      <Box component="span" sx={{ color: "error.main" }}>
        **
      </Box>
    )
  );
});

const RenderField = ({ field, value, shouldRenderInput }) => {
  const isFieldEditable = useSelector((state) => selectIsFieldEditable(state, field.id));
  return shouldRenderInput && isFieldEditable ? <RenderInputDataField field={field} /> : <RenderRawDataField field={field} dataValue={value} />;
};

const DisplayTaskFieldDetails = memo(({ taskData }) => {
  const { [routeTaskParam]: taskId } = useParams();
  const isEditModeOn = useSelector(selectIsTasksEditModeOn);
  const isConfigLoaded = useSelector(selectIsConfigDataLoaded);
  const dispatch = useDispatch();

  const updateTaskConfig = useMemo(() => {
    return updateOrRenderFormConfig.map((obj) => {
      if (obj.id === TASK_FIELDS.STATUS.ID) {
        return {
          ...obj,
          getOptionsByCurrentValue: { currentDataValue: taskData[obj.id] || null, fromConfigId: CONFIG_CONSTANTS_OPTIONS.NEXT_TASK_STATUS },
        };
      } else if (obj.id === TASK_FIELDS.PARENT_ID.ID) {
        return {
          ...obj,
          excludedValueOptions: [taskId],
        };
      }
      return obj;
    });
  }, [taskData, taskId]);

  useEffect(() => {
    if (!isConfigLoaded && isEditModeOn) {
      const isAssignedEmployeesValidField = (id) =>
        !(id === TASK_FIELDS.ASSIGNED_EMPLOYEES.ID && ["ONGOING", "PDG_START"].includes(taskData[TASK_FIELDS.STATUS.ID]));
      dispatch(
        setTasksFormConfig(
          updateTaskConfig.map(({ id, isRequired, isEditable, textFieldProps }) => ({
            id,
            isRequired: isRequired && isAssignedEmployeesValidField(id),
            isEditable: isEditable && isAssignedEmployeesValidField(id),
            value: taskData[id]?.length
              ? id === TASK_FIELDS.ASSIGNED_EMPLOYEES.ID
                ? taskData[id][0]
                : taskData[id]
              : textFieldProps?.multiple
                ? []
                : "",
          }))
        )
      );
    }
  }, [dispatch, updateTaskConfig, isEditModeOn, isConfigLoaded, taskData]);

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      {updateTaskConfig.map((field) => (
        <Grid item xs={12} sm={12} md={12} {...(field.colSpan === 1 && !isEditModeOn && { xl: 6 })} key={field.id}>
          <GridLabelValueUI
            valueSx={{
              "& .MuiSvgIcon-root, & .MuiIcon-root": { color: "primary.main" },
              "& .MuiFormControl-root": { m: 0, minWidth: "260px" },
              "& .MuiInputBase-root": { paddingRight: "14px" },
              fontSize: 14,
              ...(field.colSpan === 2 && { my: 0.5 }),
            }}
            boxContainerProps={{ flexDirection: field.colSpan === 1 ? "row" : "column" }}
            labelSx={{
              textWrap: "nowrap",
              fontSize: 14,
              fontWeight: 400,
            }}
            divider={field.colSpan === 2}
            dividerSx={{ mb: 2 }}
            label={
              <div>
                {field.label} {!!isEditModeOn && <RenderMandatoryLabelOnEdit fieldId={field.id} />}
              </div>
            }
          >
            <RenderField field={field} value={taskData[field.id]} shouldRenderInput={!!isConfigLoaded && !!isEditModeOn} />
          </GridLabelValueUI>
        </Grid>
      ))}
    </Grid>
  );
});

export default function Task({ taskData, refetchData }) {
  const dispatch = useDispatch();
  /* INFO: Redux Memory Cleanup on unmount component */
  useEffect(() => {
    return () => dispatch(clearAllTaskFormFields());
  }, [dispatch]);

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          py: 1,
          mt: 1,
          position: "sticky",
          top: 0,
          zIndex: 100,
          backgroundColor: "background.default",
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <AddTaskOutlined color="primary" sx={{ m: 1 }} />
          <Typography variant="h6">Task Details</Typography>
        </Stack>
        <EditChangesButtonGroup refetchData={refetchData} />
      </Stack>
      <Box sx={{ ...sectionUiLookBase, p: 2, my: 1 }}>
        <DisplayTaskFieldDetails taskData={taskData} />
      </Box>
    </Box>
  );
}
