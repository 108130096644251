import { ArrowBackOutlined } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import { Fragment, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { NOTIFICATION_ACTION } from "../../app/constants/config/notificationSettings";
import { NotificationDropdownButton, NotificationDropdownContainer } from "../../features/Notifications/notificationStack";
import { setPopupStatus, updateNotificationActionInBulk } from "../../features/Notifications/slice/notificationSlice";
import { MuiAppBar } from "../../styled/layoutStyles";
import { AppThemeMode, UiIconButton } from "../ui/UiUtils";
import { HeaderProfileDropDownButton, HeaderProfileDropDownMenu } from "./ProfileDropdown";

const HeaderProfileComponent = () => {
  const [ref, setRef] = useState(null);
  const onSetRef = useCallback((e) => setRef(e.currentTarget), []);

  return (
    <Fragment>
      <HeaderProfileDropDownButton onSetRef={onSetRef} />
      <HeaderProfileDropDownMenu anchorEl={ref} onClose={() => setRef(null)} />
    </Fragment>
  );
};

const NotificationComponent = () => {
  const dispatch = useDispatch();
  const [ref, setRef] = useState(null);
  const onSetRef = useCallback(
    (e) => {
      dispatch(setPopupStatus());
      if (!e?.currentTarget) dispatch(updateNotificationActionInBulk({ updateAction: NOTIFICATION_ACTION.HOVER, value: false }));
      setRef(e?.currentTarget || null);
    },
    [dispatch]
  );

  return (
    <Fragment>
      <NotificationDropdownButton onSetRef={onSetRef} />
      <NotificationDropdownContainer anchorEl={ref} onClose={() => onSetRef()} />
    </Fragment>
  );
};

export default function HeaderBar({ open, handleDrawerOpen, activeItem }) {
  const nav = useNavigate();
  const { key: locationKey } = useLocation();
  return (
    <MuiAppBar
      position="fixed"
      open={open}
      sx={{
        backgroundColor: "primary.sectionContainer",
        color: "text.primary",
        backgroundImage: "none !important",
        top: "var(--app-bar-height)",
      }}
    >
      <Toolbar>
        <Tooltip title="Open Drawer" arrow>
          <div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                padding: 1.5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon sx={{ height: "28px", width: "28px" }} />
            </IconButton>
          </div>
        </Tooltip>
        {activeItem.enableBackNav !== false && !!activeItem.parentId && (
          <UiIconButton title="Go to previous page" onClickIcon={() => nav(locationKey === "default" ? "/" : -1)} sx={{ mr: 1 }}>
            <ArrowBackOutlined />
          </UiIconButton>
        )}
        <Typography noWrap component="div" sx={{ flex: 1, fontWeight: 600, fontSize: "1.125rem", letterSpacing: "0.5px" }}>
          {activeItem.pageTitle || "Nikgil Management"}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <AppThemeMode color="primary" />
          <NotificationComponent />
          <HeaderProfileComponent />
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
}
