import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import AlertStack from "../../features/Alerts/AlertStack";
import { DebugComponent } from "../../features/Logger/debugComponent";

export default function AppLayout() {
  return (
    <Fragment>
      <AlertStack />
      <DebugComponent />
      <Outlet />
    </Fragment>
  );
}
