import React, { isValidElement, memo, useCallback, useEffect, useMemo, useRef } from "react";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";

const ROW_TYPES = {
  OPTION: "option",
  GROUP: "group",
};

const BASE_HEIGHTS = {
  [ROW_TYPES.OPTION]: 36,
  [ROW_TYPES.GROUP]: 32,
};

const OVER_SCAN_COUNT = 5;

const processChildren = (children) => {
  const itemData = [];

  React.Children.toArray(children).forEach((item, parentIndex) => {
    if (item.props.className === "MuiAutocomplete-option") {
      itemData.push({
        type: ROW_TYPES.OPTION,
        item,
        key: item.key || `option-${parentIndex}`,
      });
    } else {
      const subChildren = React.Children.toArray(item.props.children);
      if (subChildren[0]) {
        itemData.push({
          type: ROW_TYPES.GROUP,
          item: subChildren[0],
          key: `group-${parentIndex}`,
        });
      }
      const groupItems = subChildren[1]?.props?.children;
      if (groupItems) {
        React.Children.toArray(groupItems).forEach((subItem, subIndex) => {
          itemData.push({
            type: ROW_TYPES.OPTION,
            item: subItem,
            key: subItem.key || `group-${parentIndex}-option-${subIndex}`,
          });
        });
      }
    }
  });

  return itemData;
};

const VirtualizedRow = memo(({ item, style, registerChild }) => {
  if (!isValidElement(item.item)) return null;

  return React.cloneElement(item.item, {
    style: {
      ...style,
      display: "flex",
      alignItems: "center",
    },
    ref: registerChild,
  });
});

export const VirtualizedListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, role, ...other } = props;
  const listRef = useRef();
  const cacheRef = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: BASE_HEIGHTS[ROW_TYPES.OPTION],
      keyMapper: (index) => index,
    })
  );
  const processedItems = useMemo(() => processChildren(children), [children]);
  const getBaseHeight = useCallback((item) => BASE_HEIGHTS[item?.type ?? ROW_TYPES.OPTION], []);
  const listHeight = useMemo(() => {
    const minItems = 2;
    const maxVisibleItems = 4.5;
    const itemPadding = 8;

    const totalHeight =
      processedItems.length > minItems ? processedItems.reduce((acc, item) => acc + getBaseHeight(item), 0) : getBaseHeight() * minItems;

    return Math.min(totalHeight, maxVisibleItems * 60 + itemPadding);
  }, [processedItems, getBaseHeight]);

  const selectedIndex = useMemo(() => {
    const selectedIndices = processedItems
      .map((child, index) => (child.item.props["aria-selected"] === true ? index : -1))
      .filter((index) => index !== -1);
    return selectedIndices.length > 0 ? selectedIndices[selectedIndices.length - 1] : -1;
  }, [processedItems]);

  const rowRenderer = useCallback(
    ({ index, key, parent, style }) => (
      <CellMeasurer cache={cacheRef.current} columnIndex={0} key={processedItems[index].key || key} parent={parent} rowIndex={index}>
        {({ registerChild }) => <VirtualizedRow item={processedItems[index]} style={style} registerChild={registerChild} />}
      </CellMeasurer>
    ),
    [processedItems]
  );

  const listProps = useMemo(
    () => ({
      ref: listRef,
      height: listHeight,
      deferredMeasurementCache: cacheRef.current,
      rowHeight: cacheRef.current.rowHeight,
      overscanCount: OVER_SCAN_COUNT,
      rowCount: processedItems.length,
      scrollToAlignment: selectedIndex >= processedItems.length - OVER_SCAN_COUNT ? "end" : "start",
      scrollToIndex: selectedIndex,
      rowRenderer,
      role,
    }),
    [listHeight, selectedIndex, processedItems.length, rowRenderer, role]
  );

  useEffect(() => {
    cacheRef.current.clearAll();
    if (listRef.current) {
      listRef.current.recomputeRowHeights();
    }
  }, []);

  return (
    <div ref={ref}>
      <div {...other} style={{ overflow: "hidden" }}>
        <AutoSizer disableHeight>{({ width }) => <List {...listProps} width={width} />}</AutoSizer>
      </div>
    </div>
  );
});
