import { LocalShipping, Widgets } from "@mui/icons-material";
import { alpha, Box, Tab, Tabs, Typography } from "@mui/material";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PRIVATE_ROUTES, routeTaskParam, routeToInventoryTableType } from "../../app/constants/routes";
import { matchRoute } from "../../app/utils/utilityFunctions";
import { selectStockManagementPageType, setStockManagementPageType } from "../../features/InventoryManagement/slice/inventorySlice";

export const SingleTaskLabel = () => {
  const { [routeTaskParam]: id } = useParams();
  return `System Task : ${id}`;
};

const InventoryToggleSelectorLabel = memo(({ isActive, icon, label }) => (
  <Box className={`toggle-label ${isActive ? "active" : ""}`}>
    {icon}
    {label}
  </Box>
));

export const InventoryManagementToggleSelector = ({ title }) => {
  const selectedPageType = useSelector(selectStockManagementPageType);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const location = useLocation();

  const onChange = (_, value) => {
    dispatch(setStockManagementPageType(value));
    nav(routeToInventoryTableType(value));
  };

  useEffect(() => {
    const pageType = matchRoute(location.pathname, PRIVATE_ROUTES.vehicleInventoryReport).isMatch ? "inventory" : "vehicle";
    dispatch(setStockManagementPageType(pageType));
  }, [dispatch, location.pathname]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <Typography sx={{ fontSize: "inherit", fontWeight: "inherit" }}>{title}</Typography>
      <Tabs
        value={selectedPageType}
        onChange={onChange}
        sx={{
          borderRadius: (theme) => theme.shape.borderRadius * 2.5,
          border: (theme) => `1px solid ${theme.palette.divider}`,
          minHeight: 0,
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .MuiTab-root": {
            padding: 0,
            minHeight: 0,
          },

          "& .toggle-label": {
            display: "flex",
            alignItems: "center",
            gap: 1,
            p: 1,
            borderRadius: (theme) => theme.shape.borderRadius * 2.5,
            transition: (theme) => theme.transitions.create(["background-color", "box-shadow"]),
            "&.active": {
              background: (theme) =>
                `linear-gradient(180deg, ${alpha(theme.palette.success.main, 0.18)} 10%, ${alpha(theme.palette.background.paper, 1)} 60%)`,
              boxShadow: "0px 1px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), inset 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
            },
          },
        }}
      >
        <Tab
          label={<InventoryToggleSelectorLabel label="Vehicle Details" icon={<LocalShipping />} isActive={selectedPageType === "vehicle"} />}
          value="vehicle"
        />
        <Tab
          label={<InventoryToggleSelectorLabel label="Inventory Report" icon={<Widgets />} isActive={selectedPageType === "inventory"} />}
          value="inventory"
        />
      </Tabs>
    </Box>
  );
};
