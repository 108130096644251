import { Launch } from "@mui/icons-material";
import { Link, ListItemText } from "@mui/material";
import { format, formatDuration, intervalToDuration } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import { DATE_TIME_FORMAT } from "../../../app/constants/common";
import { routeToSingleTask } from "../../../app/constants/routes";
import { createTableColumn, getDateReferenceString, highlightText, serverDate, serverToUi } from "../../../app/utils/utilityFunctions";
import { EmployeeUIContent } from "../../../components/ui/UiUtils";
import { RenderInOutDateTime, renderAttendanceStatus } from "../AttendanceUIComponents";
import { conflictTypeMapping, overtimeAttendanceStatusMapping, routineAttendanceStatusMapping } from "./attendanceUtils";

/* INFO: "reference_dt" attribute is dynamically attached after api fetch in advancedTableHooks.js */
export const routineAttendanceColumnView = [
  createTableColumn({
    order: 1,
    id: "uid",
    label: "id",
    disabledColumnField: true,
    primaryKey: true,
  }),
  createTableColumn({
    order: 2,
    id: "name",
    label: "Employee Name",
    disabledColumnField: true,
  }),
  createTableColumn({
    order: 2,
    id: "ern",
    label: "Employee",
    align: "left",
    renderContent: (data, searchStr) => <EmployeeUIContent empId={data} searchStr={searchStr} />,
  }),
  createTableColumn({
    order: 3,
    id: "in_rmk",
    label: "Time In Remark",
    align: "center",
    alternateValue: "-",
    renderContent: (inRemark, searchStr) => (inRemark ? highlightText(inRemark, searchStr) : "-"),
    renderCSV: (inRemark) => (inRemark ? inRemark : "-"),
    renderPDF: (inRemark) => (inRemark ? inRemark : "-"),
  }),
  createTableColumn({
    order: 4,
    id: "in",
    label: "Time In",
    align: "center",
    alternateValue: "-",
    renderContent: (inStamp) =>
      inStamp ? <RenderInOutDateTime date={inStamp.in_dt} referenceDate={inStamp.reference_dt} time={inStamp.in_tms} /> : "-",
    renderCSV: (inStamp) => (inStamp ? serverToUi(inStamp.in_dt + inStamp.in_tms, DATE_TIME_FORMAT.UI_FULL_FORMAT) : "-"),
    renderPDF: (inStamp) => (inStamp ? serverToUi(inStamp.in_dt + inStamp.in_tms, DATE_TIME_FORMAT.UI_FULL_FORMAT) : "-"),
  }),
  createTableColumn({
    order: 5,
    id: "out",
    label: "Time Out",
    align: "center",
    alternateValue: "-",
    renderContent: (outStamp) =>
      outStamp ? <RenderInOutDateTime date={outStamp.out_dt} referenceDate={outStamp.reference_dt} time={outStamp.out_tms} /> : "-",
    renderCSV: (outStamp) => (outStamp ? serverToUi(outStamp.out_dt + outStamp.out_tms, DATE_TIME_FORMAT.UI_FULL_FORMAT) : "-"),
    renderPDF: (outStamp) => (outStamp ? serverToUi(outStamp.out_dt + outStamp.out_tms, DATE_TIME_FORMAT.UI_FULL_FORMAT) : "-"),
  }),
  createTableColumn({
    order: 6,
    id: "out_rmk",
    label: "Time Out Remark",
    align: "center",
    alternateValue: "-",
    renderContent: (outRemark, searchStr) => (outRemark ? highlightText(outRemark, searchStr) : "-"),
    renderCSV: (outRemark) => (outRemark ? outRemark : "-"),
    renderPDF: (outRemark) => (outRemark ? outRemark : "-"),
  }),
  createTableColumn({
    order: 7,
    id: "status",
    label: "Attendance Status",
    align: "center",
    renderContent: renderAttendanceStatus(routineAttendanceStatusMapping),
  }),
].sort((a, b) => a.order - b.order);

/* INFO: "reference_dt" attribute is dynamically attached after api fetch in advancedTableHooks.js */
export const conflictedAttendanceColumnView = [
  createTableColumn({
    order: 1,
    id: "req_id",
    disabledColumnField: true,
    label: "id",
    primaryKey: true,
  }),
  createTableColumn({
    order: 2,
    id: "name",
    label: "Employee Name",
    disabledColumnField: true,
  }),
  createTableColumn({
    order: 2,
    id: "ern",
    label: "Employee",
    align: "left",
    renderContent: (data, searchStr) => <EmployeeUIContent empId={data} searchStr={searchStr} />,
  }),
  createTableColumn({
    order: 3,
    id: "adj_type",
    label: "Conflict On",
    align: "center",
    renderContent: renderAttendanceStatus(conflictTypeMapping),
  }),
  createTableColumn({
    order: 4,
    id: "actual",
    label: "Actual Time",
    align: "center",
    alternateValue: "-",
    renderContent: (actTime) =>
      actTime ? <RenderInOutDateTime date={actTime.bus_dt} referenceDate={actTime.reference_dt} time={actTime.time} /> : "-",
    renderCSV: (actTime) => (actTime ? serverToUi(actTime.bus_dt + actTime.time, DATE_TIME_FORMAT.UI_FULL_FORMAT) : "-"),
    renderPDF: (actTime) => (actTime ? serverToUi(actTime.bus_dt + actTime.time, DATE_TIME_FORMAT.UI_FULL_FORMAT) : "-"),
  }),
  createTableColumn({
    order: 5,
    id: "proposed",
    label: "Proposed Time",
    align: "center",
    alternateValue: "-",
    renderContent: (propTime) =>
      propTime ? <RenderInOutDateTime date={propTime.bus_dt} referenceDate={propTime.reference_dt} time={propTime.time} /> : "-",
    renderCSV: (propTime) => (propTime ? serverToUi(propTime.bus_dt + propTime.time, DATE_TIME_FORMAT.UI_FULL_FORMAT) : "-"),
    renderPDF: (propTime) => (propTime ? serverToUi(propTime.bus_dt + propTime.time, DATE_TIME_FORMAT.UI_FULL_FORMAT) : "-"),
  }),
  createTableColumn({
    order: 5,
    id: "emp_rmk",
    label: "Remark",
    align: "center",
    alternateValue: "-",
    renderContent: (remark) => (remark ? remark : "-"),
    renderCSV: (remark) => (remark ? remark : "-"),
    renderPDF: (remark) => (remark ? remark : "-"),
  }),
].sort((a, b) => a.order - b.order);

/* INFO: "reference_dt" attribute is dynamically attached after api fetch in advancedTableHooks.js */
export const overtimeAttendanceColumnView = [
  createTableColumn({
    order: 1,
    id: "ot_id",
    label: "id",
    disabledColumnField: true,
    primaryKey: true,
  }),
  createTableColumn({
    order: 2,
    id: "name",
    label: "Employee Name",
    disabledColumnField: true,
  }),
  createTableColumn({
    order: 2,
    id: "bus_dt",
    label: "Business Date",
    disabledColumnField: true,
    searchBoolean: (data, searchStr) => (data ? format(serverDate(data), "EEEE - MMMM d, yyyy").toString().toLowerCase().includes(searchStr) : false),
  }),
  createTableColumn({
    order: 2,
    id: "ern",
    label: "Employee",
    align: "left",
    renderContent: (data, searchStr) => <EmployeeUIContent empId={data} searchStr={searchStr} />,
  }),
  createTableColumn({
    order: 2,
    id: "task_id",
    label: "Task Id",
    renderContent: (data, searchStr) => (
      <Link
        color="text.primary"
        component={RouterLink}
        to={routeToSingleTask(data)}
        underline="hover"
        sx={{ display: "flex", alignItems: "center", gap: 1 }}
        target="_blank"
        rel="noopener"
      >
        <Launch sx={{ color: "primary.main" }} />
        <span>{highlightText(data, searchStr)}</span>
      </Link>
    ),
    alternateValue: "-",
  }),
  createTableColumn({
    order: 3,
    id: "status",
    label: "Status",
    align: "center",
    renderContent: renderAttendanceStatus(overtimeAttendanceStatusMapping),
  }),
  createTableColumn({
    order: 4,
    id: "start_time",
    label: "Overtime Start",
    align: "center",
    alternateValue: "-",
    renderContent: (start, searchStr) => (start ? highlightText(serverToUi(start, DATE_TIME_FORMAT.UI_TIME_FORMAT), searchStr) : "-"),
    renderCSV: (start) => serverToUi(start, DATE_TIME_FORMAT.UI_TIME_FORMAT) || "-",
    renderPDF: (start) => serverToUi(start, DATE_TIME_FORMAT.UI_TIME_FORMAT) || "-",
  }),
  createTableColumn({
    order: 5,
    id: "end_time",
    label: "Overtime End",
    align: "center",
    alternateValue: "-",
    renderContent: (end, searchStr) => (end ? highlightText(serverToUi(end, DATE_TIME_FORMAT.UI_TIME_FORMAT), searchStr) : "-"),
    renderCSV: (end) => serverToUi(end, DATE_TIME_FORMAT.UI_TIME_FORMAT) || "-",
    renderPDF: (end) => serverToUi(end, DATE_TIME_FORMAT.UI_TIME_FORMAT) || "-",
  }),
  createTableColumn({
    order: 6,
    id: "durationMinutes",
    label: "Total Duration",
    align: "center",
    alternateValue: "-",
    renderContent: (mins) => (mins ? formatDuration(intervalToDuration({ start: 0, end: mins * 60 * 1000 }), { format: ["hours", "minutes"] }) : "-"),
    renderCSV: (mins) => (mins ? formatDuration(intervalToDuration({ start: 0, end: mins * 60 * 1000 }), { format: ["hours", "minutes"] }) : "-"),
    renderPDF: (mins) => (mins ? formatDuration(intervalToDuration({ start: 0, end: mins * 60 * 1000 }), { format: ["hours", "minutes"] }) : "-"),
  }),
  createTableColumn({
    order: 7,
    id: "remark",
    label: "Employee Remark",
    align: "center",
    alternateValue: "-",
    renderContent: (remark) => (remark ? remark : "-"),
    renderCSV: (remark) => (remark ? remark : "-"),
    renderPDF: (remark) => (remark ? remark : "-"),
  }),
  createTableColumn({
    order: 8,
    id: "appr_name",
    label: "Reviewer Name",
    disabledColumnField: true,
  }),
  createTableColumn({
    order: 8,
    id: "appr_id",
    label: "Reviewed By",
    renderContent: (data, searchStr) => <EmployeeUIContent empId={data} searchStr={searchStr} />,
  }),
  createTableColumn({
    order: 9,
    id: "appr_remark",
    label: "Review Remark",
    align: "center",
    alternateValue: "-",
    renderContent: (remark) => (remark ? remark : "-"),
    renderCSV: (remark) => (remark ? remark : "-"),
    renderPDF: (remark) => (remark ? remark : "-"),
  }),
].sort((a, b) => a.order - b.order);

/* INFO: Dialog Box Component Labels Config */
export const conflictDialogConfig = (row) =>
  [
    {
      id: "name",
      label: "Employee Name",
      valueComponent: row["name"] || "N/A",
      order: 1,
    },
    {
      id: "adj_type",
      label: "Conflict Type",
      valueComponent: row["adj_type"] || "N/A",
      order: 2,
    },
    {
      id: "actual",
      label: "System Recorded Punch Time",
      valueComponent: (
        <ListItemText
          primary={serverToUi(row.actual.time, DATE_TIME_FORMAT.UI_TIME_FORMAT)}
          secondary={getDateReferenceString(row.actual.bus_dt, row.actual.bus_dt)}
        />
      ),
      order: 3,
    },
    {
      id: "proposed",
      label: "Employee Proposed Punch Time",
      valueComponent: (
        <ListItemText
          primary={serverToUi(row.proposed.time, DATE_TIME_FORMAT.UI_TIME_FORMAT)}
          secondary={getDateReferenceString(row.proposed.bus_dt, row.actual.bus_dt)}
        />
      ),
      order: 4,
    },
    {
      id: "emp_rmk",
      label: "Employee Remark",
      valueComponent: row.emp_rmk || "None",
      order: 5,
    },
  ].sort((a, b) => a.order - b.order);
