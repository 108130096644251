import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  ListItem,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { debounce } from "lodash";
import { MuiColorInput } from "mui-color-input";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { IS_CHECKBOX, IS_COLOR, IS_DATE, IS_MULTILINE, IS_SELECT, IS_TIME } from "../../app/constants/config/DynamicFields";
import { autocompleteGroupPopperStyle } from "../../styled/inlineCssStyles";
import { VirtualizedListboxComponent } from "../../styled/virtualizedList";
import MultiLineRTE from "../RichTextEditor/RichTextEditor";
import DateTimeRangePickerDialogModal, { SingleDatePicker, SingleTimePicker } from "./DateTimePickerModal";
import { LoadingProgress } from "./UiUtils";

export const SelectOptionsField = ({
  id = "select-field-no-id",
  name = "select-field-no-name",
  label,
  value,
  onChange,
  multiple = false,
  displayEmpty = false,
  renderValue = null,
  customOptionEnabled = false,
  customOption = null,
  onCustomOptionSelected = () => {},
  children,
  fieldStyleProps = {},
  ...otherProps
}) => {
  const handleChange = (event) => {
    const selectedValue = multiple ? (event.target.value ?? []).map((val) => val.toString()) : event.target.value;
    if (customOptionEnabled && typeof selectedValue === "string" && selectedValue === customOption) {
      event.stopPropagation();
      onCustomOptionSelected(selectedValue);
    } else onChange(selectedValue);
  };

  return (
    <TextField
      sx={fieldStyleProps}
      fullWidth
      select
      value={value}
      onChange={handleChange}
      variant="outlined"
      size="small"
      id={id}
      name={name}
      label={label}
      SelectProps={{
        displayEmpty,
        sx: { "& .MuiSelect-select": { display: "flex", alignItems: "center", flexWrap: "wrap" } },
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 60 * 4.5 + 8,
            },
          },
        },
        multiple,
        ...(renderValue ? { renderValue: (selected) => renderValue(selected) } : multiple ? { renderValue: (selected) => selected.join(", ") } : {}),
      }}
      {...otherProps}
    >
      {children}
    </TextField>
  );
};

export const AutocompleteOptionsField = ({
  id = "autocomplete-field-no-id",
  name = "autocomplete-field-no-name",
  label,
  value,
  onChange,
  InputProps,
  inputProps,
  type,
  uniqueKeyId,
  multiple = false,
  disableClearable = true,
  placeholder = "",
  variant = "outlined",
  listBoxSxProps = {},
  popperSxProps = {},
  sx = {},
  filterFunction,
  size = "small",
  options = [],
  renderOptionComponent,
  otherInFieldProps = {},
  loading,
  ...otherProps
}) => {
  const handleChange = (e, selectedValue) => {
    if (multiple && e.key === "Backspace") return;
    onChange(selectedValue);
  };

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      autoComplete
      disableClearable={disableClearable}
      disableCloseOnSelect={multiple}
      size={size}
      multiple={multiple}
      value={value}
      onChange={handleChange}
      options={options}
      ListboxComponent={VirtualizedListboxComponent}
      filterOptions={
        filterFunction
          ? (options, { inputValue }) => (inputValue ? options.filter((option) => filterFunction(option, inputValue)) : options)
          : undefined
      }
      disableListWrap
      renderOption={
        renderOptionComponent
          ? (props, option) => (
              <ListItem {...props} {...(uniqueKeyId && { key: option[uniqueKeyId] })} divider sx={{ m: 0 }}>
                {renderOptionComponent(option)}
              </ListItem>
            )
          : undefined
      }
      renderInput={(params) => (
        <TextField
          {...params}
          id={id}
          name={name}
          size={size}
          label={label}
          variant={variant}
          placeholder={placeholder}
          type={type}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
            startAdornment: (
              <Fragment>
                {InputProps?.startAdornment}
                {params.InputProps.startAdornment}
              </Fragment>
            ),
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {InputProps?.endAdornment}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          inputProps={{ ...params.inputProps, ...inputProps }}
          {...otherInFieldProps}
        />
      )}
      loadingText={<LoadingProgress size={24} />}
      ListboxProps={{ sx: { maxHeight: 64 * 4.5 + 8, ...listBoxSxProps } }}
      slotProps={{ paper: { elevation: 3 }, popper: { sx: { ...autocompleteGroupPopperStyle, ...popperSxProps } } }}
      sx={{ "& .MuiChip-label": { fontSize: 12, fontWeight: 500 }, ...sx }}
      loading={loading}
      {...otherProps}
    />
  );
};

export const SelectOptionsFieldWithCustomConfig = ({ selectConfig }) => {
  const { selectedOption, onChangeData } = selectConfig.useValueProvider();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const customOption = selectConfig.customOption ?? null;

  const onChange = (value) => {
    onChangeData(selectConfig.options.find((option) => option.value === value).returnDataValue);
  };

  const openCustomOption = (e) => {
    e.stopPropagation();
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogConfirm = (data) => {
    setIsDialogOpen(false);
    const { from, to } = data;
    onChangeData({ value: customOption.value, from, to });
  };

  return (
    <Fragment>
      <SelectOptionsField
        label={selectConfig.inputLabel}
        value={selectedOption}
        onChange={onChange}
        customOptionEnabled
        customOption={customOption?.value}
        onCustomOptionSelected={() => setIsDialogOpen(true)}
        size={selectConfig.size || "medium"}
        fieldStyleProps={{
          backgroundColor: "primary.sectionContainer",
          m: 0.5,
          minWidth: "155px",
          ...(selectConfig.sx || {}),
        }}
      >
        {selectConfig.options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
        {!!customOption && (
          <MenuItem onClick={openCustomOption} value={customOption.value}>
            <Typography fontSize="inherit" color="inherit" fontWeight={600} fontStyle="italic">
              {customOption.label}
            </Typography>
          </MenuItem>
        )}
      </SelectOptionsField>
      {!!customOption && !!isDialogOpen && (
        <DateTimeRangePickerDialogModal
          onHandleClose={handleDialogClose}
          onHandleConfirm={handleDialogConfirm}
          valueProviderHook={selectConfig.useValueProvider}
          datePickerProps={selectConfig.datePickerPropsArray || undefined}
        />
      )}
    </Fragment>
  );
};

export const RenderOnDemandField = ({
  optionsData = [],
  isFieldDisabled = false,
  fieldType,
  field,
  fieldValue,
  onChange,
  fieldError = false,
  loadingOptions = false,
  errorLoading = false,
  errorString = "",
  label,
  size = "small",
  disableClearable = false,
  getFromValue,
  setToValue,
}) => {
  const theme = useTheme();
  const [state, setState] = useState(fieldValue || "");

  /* INFO: Dependent Select Fields Needs To Know Change In Value Of Parent Select Field  */
  useEffect(() => {
    setState(fieldValue);
  }, [fieldValue, setState]);

  const debouncedOnChangeRef = useRef(debounce((input) => onChange?.(input), 600));

  /* INFO: Initialize the debounced function once, Capture the current debounced function in a variable to avoid react warning   */
  useEffect(() => {
    const debouncedFunction = debouncedOnChangeRef.current;
    return () => debouncedFunction.cancel();
  }, []);

  const onChangeValue = useCallback(
    (changedValue) => {
      const pureValue = setToValue?.(changedValue) || changedValue;
      setState(pureValue);
      debouncedOnChangeRef.current(pureValue);
    },
    [setToValue, setState]
  );

  return IS_SELECT(fieldType) ? (
    <AutocompleteOptionsField
      disabled={isFieldDisabled}
      value={getFromValue !== undefined ? getFromValue(optionsData, state) || null : state}
      onChange={onChangeValue}
      noOptionsText="No Options Available"
      options={optionsData}
      loading={loadingOptions}
      label={label}
      size={size}
      disableClearable={disableClearable}
      {...(field?.textFieldProps || {})}
      {...(errorLoading && { noOptionsText: "Error Loading Options. Reload Form" })}
      renderOptionComponent={
        field?.textFieldProps?.renderOptionComponent ? (option) => field.textFieldProps.renderOptionComponent(option, theme) : undefined
      }
      otherInFieldProps={{
        helperText: !!fieldError && errorString,
        error: fieldError,
      }}
    />
  ) : IS_DATE(fieldType) ? (
    <FormControl fullWidth>
      <SingleDatePicker
        disabled={isFieldDisabled}
        defaultDate={state}
        size={size}
        label={label}
        onChange={onChangeValue}
        disableClearable={disableClearable}
        {...(field?.componentProps || {})}
      />
    </FormControl>
  ) : IS_TIME(fieldType) ? (
    <FormControl fullWidth>
      <SingleTimePicker
        disabled={isFieldDisabled}
        size={size}
        defaultTime={state}
        label={label}
        onChange={onChangeValue}
        {...(field?.componentProps || {})}
      />
    </FormControl>
  ) : IS_MULTILINE(fieldType) ? (
    <MultiLineRTE value={state} onChange={onChangeValue} {...field?.textFieldProps} />
  ) : IS_COLOR(fieldType) ? (
    <MuiColorInput
      format="hex"
      isAlphaHidden
      value={state}
      onChange={onChangeValue}
      size="small"
      variant="outlined"
      fullWidth
      disabled={isFieldDisabled}
      {...(field?.textFieldProps || {})}
      helperText={!!fieldError && errorString}
      error={fieldError}
    />
  ) : IS_CHECKBOX(fieldType) ? (
    <FormControlLabel
      control={
        <Checkbox checked={getFromValue !== undefined ? getFromValue(state) || false : state} onChange={(e) => onChangeValue(e.target.checked)} />
      }
      {...(field?.textFieldProps || {})}
    />
  ) : (
    <TextField
      value={state}
      onChange={(e) => onChangeValue(e.target.value)}
      size={size}
      label={label}
      variant="outlined"
      fullWidth
      disabled={isFieldDisabled}
      {...(field?.textFieldProps || {})}
      helperText={!!fieldError && errorString}
      error={fieldError}
    />
  );
};
